import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import mainSectionImg from "../../../../../assets/images/mobile.png";
import downloadItems from "../../../../../constants/_downloadItems";
import arrowTopIcon from "../../../../../assets/icons/arrow top.png";

export default function TopSection() {
  return (
    <div>
      <Container>
        <Row className="flex-column-reverse flex-md-row">
          <Col xs="12" md="6" lg="5">
            <Row>
              <h1 className="white-space-pre-wrap h2 text-center text-md-start">
                با پیشرو پرداخت{"\n"}امن و مطمئن پرداخت کن
              </h1>
              {downloadItems.map((item, index) => (
                <Col key={index} xs="4">
                  <img
                    className="w-100"
                    src={item.img}
                    alt={`download-app-${index}`}
                  />
                </Col>
              ))}
              <Col
                className="d-flex flex-center text-center row-gap-2 flex-column"
                xs="12"
              >
                <img width={50} src={arrowTopIcon} alt="arrowTopIcon" />
                <h6>دانلود اپلیکیشن</h6>
              </Col>
            </Row>
          </Col>
          <Col xs="12" md="6" lg="5">
            <img className="w-100" src={mainSectionImg} alt="app-sample" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
