import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { axios } from "../../../../../boot";
import BlogCard from "../../../../../components/BlogCard";
export default function LastNews() {
  const [blogList, setBlogList] = useState([]);
  const getBlogList = () => {
    const url = "/blog/getPosts";
    const params = { limit: 3 };
    axios.get(url, { params }).then(({ data }) => {
      setBlogList(data.posts);
    });
  };
  useEffect(getBlogList, []);
  return (
    <Container className="lastNews">
      <Row>
        <div className="text-center">
          <h1 className="h3">آخرین اخبار و مقالات</h1>
          <p>نگاهی به آخرین اخبار</p>
        </div>
        {blogList.map((props, index) => (
          <Col key={index} xs="12" md="6" lg="4">
            <BlogCard {...props} />
          </Col>
        ))}
        <Col xs="12" className="d-flex flex-center">
          <Link
            to="/blog"
            className="btn btn-primary bg-transparent text-primary w-fit mt-4"
          >
            مشاهده همه اخبار<i className="bi bi-arrow-left"></i>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}
