import React, { useEffect, useRef, useState } from "react";
import { Col } from "react-bootstrap";
import { cloneDeep } from "lodash";
import { axios } from "../../../boot";
import {
  Button,
  CardAlert,
  Form,
  Input,
  Modal,
  Table,
} from "../../../components";
import { rules } from "../../../constants";
import { moment } from "../../../boot";
import { getBankName, toast } from "../../../methods";

export default function BankCards() {
  const inputFile = useRef();
  const [data, setData] = useState({});
  const [bankCards, setBankCards] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);

  const handleUploadFile = ({ target }) => {
    const file = target.files[0];
    setData((p) => ({ ...p, image: file, imageTitle: file?.name }));
  };
  const handleAddNewCard = () => {
    const max = 3;
    if (bankCards.length >= max) {
      const text = `امکان اصافه کردن بیش از ${max} کارت وجود ندارد`;
      return toast({ text, type: "error" });
    }
    setShowAddModal(true);
  };
  const getBankCards = () => {
    const url = "/creditcards/get_cards";
    axios.post(url).then((res) => {
      setBankCards(res.data);
    });
  };
  const setBankName = () => {
    if (data?.cardNumber?.length >= 6) {
      const bankName = getBankName(data.cardNumber);
      setData((p) => ({ ...p, bankName }));
    } else {
      setData((p) => ({ ...p, bankName: null }));
    }
  };
  const submitNewCard = () => {
    const url = "/creditcards/add_card";
    const body = new FormData();
    const unnecessary = ["imageTitle"];
    Object.keys(data).forEach((key) => {
      unnecessary.includes(key) || body.set(key, data[key]);
    });
    axios.post(url, body).then(() => {
      const newBankData = cloneDeep(data);
      const text = "کارت با موفقیت ثبت شد.";
      newBankData.createdAt = new Date().getTime();
      newBankData.cardNumber = data.cardNumber;
      setBankCards((p) => [...p, newBankData]);
      setData({});
      toast({ text });
      setShowAddModal(false);
    });
  };
  const inputs = [
    {
      label: "شماره کارت :",
      state: "cardNumber",
      append: data.bankName && (
        <span className="input-group-text">{data.bankName}</span>
      ),
      type: "number",
      rules: rules.cardNumber,
    },
    {
      label: "شماره حساب :",
      state: "hesabNumber",
      type: "number",
      rules: rules.accountNumber,
    },
    {
      label: "شماره شبا :",
      state: "shebaNumber",
      append: <span className="input-group-text">IR</span>,
      type: "number",
      rules: rules.shebaNumber,
    },
    {
      label: "تصویر کارت بانکی :",
      state: "image",
      type: "file",
      rules: rules.required,
    },
  ];

  useEffect(setBankName, [data.cardNumber]);
  useEffect(getBankCards, []);
  return (
    <div className="BankCard">
      <div className="bg-white px-3 py-3 shadow-sm">
        <div className="d-flex justify-content-between">
          <h5 className="text-violet">کارت‌های بانکی</h5>
          <Button variant="violet" onClick={handleAddNewCard}>
            افزودن کارت جدید
          </Button>
        </div>
        <Table className="mt-3 bg-light-blue rounded-5 text-secondary">
          <thead>
            <tr>
              {/* <td>نام بانک</td> */}
              <td />
              <td>نام بانک</td>
              <td>شماره کارت</td>
              <td>شماره حساب</td>
              <td>شماره شبا</td>
              <td>تاریخ ایجاد</td>
              {/* <td /> */}
            </tr>
          </thead>
          <tbody className="bg-white border-white">
            {bankCards.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.bankName}</td>
                <td>{item.cardNumber}</td>
                <td>{item.hesabNumber}</td>
                <td>{item.shebaNumber}</td>
                <td>{moment.miladiToShamsi({ date: item.createdAt })}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Modal
        show={showAddModal}
        onHide={setShowAddModal}
        variant="violet"
        title="کارت‌های بانکی جدید"
        closeBtn
      >
        <Form onSubmit={submitNewCard} className="row">
          {inputs.map((item, index) => (
            <Col key={index} xs="12">
              <Input
                {...item}
                value={data[item.state]}
                setValue={(val) =>
                  setData((p) => ({ ...p, [item.state]: val }))
                }
              />
            </Col>
          ))}
          <Col xs="12">
            <CardAlert />
          </Col>
          <input
            ref={inputFile}
            type="file"
            className="d-none"
            accept=".png, .jpg"
            onInput={handleUploadFile}
          />
          <div className="col-12 d-flex flex-center">
            <Button
              type="submit"
              variant="violet"
              className="me-auto"
              size="md"
            >
              ذخیره کارت
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
