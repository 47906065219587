import React from "react";
import middleSectionImage from "../../../../../assets/images/middle section/cards.svg";

export default function MiddleSection() {
  return (
    <div className="middleSection bg-primary d-flex flex-center row text-center">
      <p className="text-white h4 fw-bold white-space-pre-wrap mt-5 ">
        هم اکنون می‌توانید تمام خریدها و خدمات {"\n"}بانکی خود را با پیشرو
        پرداخت انجام دهید
      </p>
      <img src={middleSectionImage} height={300} alt="middleSectionImage" />
    </div>
  );
}
