import { useEffect, useState } from "react";
import { Dropdown, FormControl } from "react-bootstrap";
import { Input } from "..";

export default function Select({
  label = "",
  value = "",
  items = [],
  itemLabel = "name",
  itemValue = "id",
  rules = [],
  filter = false,
  setValue = () => {},
}) {
  const [filterText, setFilterText] = useState("");
  const renderFilterItems = () => {
    if (filter)
      return items.filter(
        (e) =>
          e[itemLabel]
            .toLocaleLowerCase()
            .search(filterText.toLocaleLowerCase()) !== -1
      );
    return items;
  };
  const showValue = () => {
    const defaultValue = {
      [itemLabel]: "",
      [itemValue]: "",
    };
    const val =
      items.find((e) => `${e[itemValue]}` === `${value}`) ?? defaultValue;
    return val[itemLabel];
  };
  useEffect(() => setFilterText(""), [value]);
  return (
    <Dropdown onSelect={setValue} className="Input w-100 check-valid">
      <Dropdown.Toggle as="span">
        <Input
          value={showValue()}
          label={label}
          append={<i className="input-group-text fs-5 bi bi-caret-down-fill" />}
          readOnly
          rules={rules}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu className="overflow-auto" style={{ maxHeight: "17.5rem" }}>
        {filter && (
          <div className="dropdown-item">
            <FormControl
              value={filterText}
              onInput={({ target }) => setFilterText(target.value)}
            />
          </div>
        )}
        {renderFilterItems().map((item, index) => (
          <Dropdown.Item key={index} eventKey={item[itemValue]}>
            {item[itemLabel]}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
