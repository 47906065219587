import { Alert } from "react-bootstrap";

export default function CardAlert() {
  return (
    <Alert variant="warning" className="w-100">
      <i className="bi bi-exclamation-triangle-fill fs-5 ms-1" />
      لطفا هنگام گرفتن عکس از کارت بانکی، تاریخ انقضا و cvv2 را بپوشانید
    </Alert>
  );
}
