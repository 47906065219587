import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Badge, Table } from "../../../../../components";
import { payStatus } from "../../../../../constants";

export default function LastBuySection({ orders = [] }) {
  const showStatus = (id = 0) => {
    const { name, color } = payStatus.find((e) => e.id === id);
    return <Badge label={name} variant={color} />;
  };
  return (
    <Col xs="12" md="6">
      <div className="wrapper">
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="text-violet">خریدهای اخیر</h5>
          <Link
            to={{ pathname: "/buy-cryptos", search: "tab=orders" }}
            className="d-flex flex-center text-blue"
          >
            مشاهده همه
            <i className="bi bi-arrow-left" />
          </Link>
        </div>
        <Table>
          <thead className="text-secondary">
            <tr>
              <td>سفارش</td>
              <td>وضعیت</td>
              <td>ارز</td>
              <td>مبلغ(ت)</td>
            </tr>
          </thead>
          <tbody>
            {orders.map((e) => (
              <tr key={e._id}>
                <td>{e._id.substring(0, 7)}</td>
                <td>{showStatus(e.orderStatus)}</td>
                <td>{e.coin?.persianName}</td>
                <td>{parseInt(Number(e.pay)).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Col>
  );
}
