import React, { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { axios , moment } from "../../../boot";
import { Button, Form, Input } from "../../../components";
import { rules } from "../../../constants";
import { copyText, source, toast } from "../../../methods";
import "./index.scss";

export default function Profile() {
  const inputFile = useRef();
  const dispatch = useDispatch();

  const profile = useSelector((s) => s.profile);
  console.log(profile)
  const [changePasswordData, setChangePasswordData] = useState({});
  const copyCode = (state = "") => {
    let value = profile[state]
    copyText(value)
      .then(() => {
        const text = "کپی شد.";
        toast({ text });
      })
      .catch(() => {
        const text = "خطا در کپی کد معرف لطفا مجددا تلاش کنید.";
        toast({ text, type: "error" });
      });
  };
  const setProfileImage = (image) => {
    dispatch({ type: "SET_PROFILE_IMAGE", image });
  };
  const submitChangePassword = () => {
    const url = "/user/changePassword";
    const body = { ...changePasswordData };
    delete body.confirmNewPassword;
    axios.post(url, body).then(() => {
      const text = "تغییر رمز عبور شما با موفقیت انجام شد.";
      toast({ text });
      setChangePasswordData({});
    });
  };
  const uploadFile = (e) => {
    const file = e.target.files[0];
    const url = "/user/uploadProfileimage";
    const body = new FormData();
    body.append("image", file);
    axios.post(url, body).then(({ data }) => {
      const imageUrl = data.address;
      setProfileImage(imageUrl);
      const text = "آپلود عکس موفقیت آمیز بود";
      toast({ text });
    });
  };
  const profileInputs = [
    {
      label: "نام و نام خانوادگی",
      state: "name",
    },
    {
      label: "ایمیل",
      state: "email",
    },
    {
      label: "شماره تماس",
      state: "phone",
    },
    {
      label: "کد ملی",
      state: "codeMelli",
    },
    {
      label: "کد معرف",
      state: "myreferalCode",
      append: <button onClick={()=>copyCode("myreferalCode")} className="bi bi-files" />,
    },
    {
      label: "آدرس",
      state: "address",
    },
    {
      label: "جنسیت",
      state: "gender",
      rules : [
        (val = "") => {
          if(val == "male") return "مرد"
          else return "زن"
        }
          
          
      ]
    },
    {
      label: "تاریخ تولد",
      state: "birthday",
    },
  ];

  const gender = (val = "") => {
    if(val == "male"){
      return "مرد"
    }else if(val == "female") {
      return "زن"
    }else{
      return null
    }

  }

  const birthday = (val) => {
    if(val){
      return moment.miladiToShamsi({ date: val })
    }else{
      return null
    }
  }

  const ChangePasswordInputs = [
    {
      label: "رمز ورود قدیمی",
      placeholder: "رمز قدیمی خود را وارد کنید",
      type: "password",
      state: "password",
      rules: rules.password,
    },
    {
      label: "رمز ورود جدید",
      placeholder: "رمز ورود جدید خود را وارد کنید",
      type: "password",
      state: "newpassword",
      rules: rules.password,
    },
    {
      label: "تکرار رمز ورود جدید",
      placeholder: "رمز ورود جدید خود را مجددا وارد کنید",
      type: "password",
      state: "confirmNewPassword",
      rules: [
        (val = "") =>
          changePasswordData.newpassword === val || "رمز عبور مطابقت ندارد.",
      ],
    },
  ];
  return (
    <div className="Profile wrapper">
      <Row className="row-gap-5 justify-content-start">
        <Col xs="12" className="d-flex flex-center">
          <div className="profile-img position-relative">
            <div className="profile-box position-relative d-flex flex-center rounded-circle bg-light overflow-hidden">
              {profile.profilePic ? (
                <img
                  className="bg-img"
                  src={source(profile.profilePic)}
                  alt="profile"
                />
              ) : (
                <i className="bi bi-person text-gray d-flex flex-center" />
              )}
            </div>
            <i
              onClick={() => inputFile.current.click()}
              className="bi bi-camera d-block d-flex flex-center bg-violet text-white rounded-circle position-absolute bottom-0 right-0 cursor-pointer"
            ></i>
            <input
              accept=".jpg, .png"
              ref={inputFile}
              type="file"
              className="d-none"
              onInput={uploadFile}
            />
          </div>
        </Col>
        {profileInputs.map((e) => (
          <Col key={e.state} xs="12" lg="6">
            <h6>
              {e.label}:{" "}
              <span className="text-secondary">
                { (e.state != "gender" &&  e.state != "birthday" ) &&  (profile[e.state]  ?? "ثبت نشده") }
                { (e.state == "gender") && (gender(profile[e.state]) ?? "ثبت نشده") }
                { (e.state == "birthday") && (birthday(profile[e.state]) ?? "ثبت نشده") }
                {e.append}


              </span>
            </h6>
          </Col>
        ))}
      </Row>
      <Form onSubmit={submitChangePassword} className="row mt-4">
        <h6 className="fw-bold col-12">تغییر رمز ورود</h6>
        {ChangePasswordInputs.map((item, index) => (
          <Col key={index} xs="12" lg="6">
            <Input
              {...item}
              value={changePasswordData[item.state]}
              setValue={(val) =>
                setChangePasswordData((p) => ({ ...p, [item.state]: val }))
              }
            />
          </Col>
        ))}
        <Col xs="12" className="d-flex flex-center">
          <Button type="submit" className="text-white">
            ثبت تغییرات
          </Button>
        </Col>
      </Form>
    </div>
  );
}
