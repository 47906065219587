const ticketStatus = [
  {
    name: "تیکت جدید",
    id: 0,
    color: "success",
  },
  {
    name: "در حال پاسخگویی",
    id: 1,
    color: "primary",
  },
  {
    name: "بسته شده",
    id: 2,
    color: "danger",
  },
];
export default ticketStatus;
