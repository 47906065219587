import axios from "axios";
import { server } from "../../constants";
import { toast } from "../../methods";
import store from "../../redux";
import { useNavigate } from 'react-router-dom';


// const redirectHandler = () => {
//   const navigate = useNavigate()
//   return navigate('/')
// }

axios.defaults.baseURL = `${server}/api`;
const token = localStorage.getItem("token");
token && (axios.defaults.headers.Authorization = token);

const setIsLogged = (data) => {
  store.dispatch({ type: "SET_IS_LOGGED", data });
};


axios.interceptors.request.use((request) => {
  store.dispatch({ type: "PUSH_LOADING" });
  return request;
});
axios.interceptors.response.use(
  (response) => {
    store.dispatch({ type: "POP_LOADING" });
    // const text = response?.data?.message;
    // typeof text === "string" && toast({ text });
    return response;
  },
  (error) => {
    store.dispatch({ type: "POP_LOADING" });
    if (error.response.status == 403) {
      // const navigate = useNavigate()

      localStorage.removeItem("token");
      delete axios.defaults.headers.token;
      setIsLogged(false)
      
      window.location.href = "/";
     }
    const status = error?.response?.status;
    const message = status ? `Error ${status}` : "Network Error";
    const text = error?.response?.data?.message ?? message;
    toast({ type: "error", text });
    return Promise.reject(error);
  }
);
export default axios;
