export default function Badge({
  label = "",
  variant = "primary",
  color = "white",
}) {
  return (
    <span
      className={`Badge rounded-pill px-3 py-0 fs-7 bg-${variant} text-${color}`}
    >
      {label}
    </span>
  );
}
