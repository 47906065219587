import React, { useEffect, useState } from "react";
import { Button, CreditOffline, Modal, Table } from "../../../components";
import { axios, moment } from "../../../boot";
import { getBankName } from "../../../methods";
import { creditStatus } from "../../../constants";
import { Alert } from "react-bootstrap";
export default function IncreaseCreditOffline() {
  const [payList, setPayList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [ rejectText , setRejectText ] = useState("")
  const [showModal2, setShowModal2] = useState(false);

  const getPayList = () => {
    const url = "/pay/getUserOfflinePay";
    axios.post(url).then(({ data }) => {
      setPayList(data.pays);
    });
  };
  const showStatus = (status = 0) => {
    const { color, name } = creditStatus.find((e) => e.id === status);
    return (
      <Alert variant={color} className="w-fit border-0 p-2 mx-auto">
        {name}
      </Alert>
    );
  };
  useEffect(getPayList, []);
  return (
    <div className="IncreaseCreditOffline">
      <Modal show={showModal} onHide={setShowModal}>
        <CreditOffline
          afterSubmit={() => {
            getPayList();
            setShowModal(false);
          }}
        />
      </Modal>

      <Modal title="علت رد شدن" variant="danger" show={showModal2} onHide={setShowModal2}>
        <p className="text-center">{rejectText}</p>
      </Modal>
      <div className="bg-white p-3 shadow-sm rounded-5">
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="text-violet">افزایش اعتبار آفلاین</h5>
          <Button
            onClick={() => setShowModal(true)}
            className="btn btn-violet rounded-5 px-4 py-2"
          >
            افزایش اعتبار آفلاین
          </Button>
        </div>
        <Table className="text-center bg-light-blue rounded-5 mt-2">
          <thead className="text-secondary">
            <tr>
              <td>#</td>
              <td>مبلغ(تومان)</td>
              <td>واریز به</td>
              <td>کد پیگیری</td>
              <td>وضعیت</td>
              <td>آخرین بروزرسانی</td>
            </tr>
          </thead>
          <tbody className="border-white bg-white">
            {payList.map((e, i) => (
              <tr key={e._id}>
                <td>{i + 1}</td>
                <td>{Number(e.amount).toLocaleString()}</td>
                <td className="white-space-pre-wrap ">
                  {getBankName(e.toAccount.cardNumber)}
                  {"\n"}
                  {e.toAccount.cardNumber}
                </td>
                <td>{e.refNumber ?? "-"}</td>
                <td onClick={()=>{if(e.status == 2){setShowModal2(true);};setRejectText(e.adminDecription) }}>{showStatus(e.status)}</td>
                {/* <td>{e.adminDecription}</td> */}
                <td dir="rtl">
                  {moment.miladiToShamsi({ date: e.updatedAt })}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
