import React, { useEffect, useState } from "react";
import { Accordion } from "../../../components";
import { axios } from "../../../boot";
import { useSeenMessage  } from "../../../hooks";
import MessAccordion from "./MessAccordion";
export default function Messages() {
  const [messages, setMessages] = useState([]);
  const message = useSeenMessage()
  console.log(message)
  const getMessages = () => {
    const url = "/message/getMessage";
    axios.post(url).then(({ data }) => {
      setMessages(data);
      console.log(data)
    });
  };
  useEffect(getMessages, []);
  return (
    <div className="Messages d-flex flex-column row-gap-3">
      {messages.map((mess) => (
        <MessAccordion item={mess} color="primary" />
      ))}
    </div>
  );
}
