import React from "react";
import { ButtonGroup } from "react-bootstrap";
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Button } from "../../../components";
import AllCryptos from "./AllCryptos";
import FavCryptos from "./FavCryptos";
import Orders from "./Orders";
export default function Cryptos() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const isBuy = location.pathname.search("/buy-cryptos") !== -1;
  const transactionLabel = isBuy ? "خرید" : "فروش";
  const initActiveTab = searchParams.get("tab") ?? "all-cryptos";
  const tabs = [
    {
      label: "همه ارزها",
      id: "all-cryptos",
      component: AllCryptos,
    },
    {
      label: "علاقه‌مندی‌ها",
      id: "favorites",
      component: FavCryptos,
    },
    {
      label: `لیست ${transactionLabel}‌ها`,
      id: "orders",
      component: Orders,
    },
  ];
  const activeTab = initActiveTab;
  const setActiveTab = (tab) => setSearchParams({ tab }, { replace: true });
  const ActiveComponent = tabs.find((e) => e.id === activeTab)?.component;
  return (
    <div className="bg-white shadow-sm rounded p-3">
      <h5 className="text-violet py-2">{transactionLabel} ارز</h5>
      <ButtonGroup>
        {tabs.reverse().map((item, index) => (
          <Button
            key={index}
            size="sm"
            variant={activeTab === item.id ? "primary" : "light-gray"}
            outline={activeTab === item.id}
            onClick={() => setActiveTab(item.id)}
            className="pb-1 border-bottom-0"
          >
            {item.label}
          </Button>
        ))}
      </ButtonGroup>
      {ActiveComponent && (
        <ActiveComponent isBuy={isBuy} transactionLabel={transactionLabel} />
      )}
    </div>
  );
}
