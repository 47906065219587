import React, { useState, useEffect, useRef } from "react";
import { Pagination, Table } from "../../../../../components";
import { axios } from "../../../../../boot";
import { Container } from "react-bootstrap";
export default function Cryptos() {
  const tableRef = useRef();
  const [cryptos, setCryptos] = useState({});
  const [activePage, setActivePage] = useState(1);
  const getAllCryptos = () => {
    const url = "/coin/get_price";
    const body = { page: activePage };
    axios.post(url, body).then(({ data }) => {
      setCryptos(data);
    });
  };
  const showChangeValue = (val = 0) => {
    if (val < 0) return <span className="text-danger">{val}%</span>;
    if (val > 0) return <span className="text-success">+{val}%</span>;
    return <span className="text-dark">{0}</span>;
  };

  useEffect(getAllCryptos, [activePage]);
  return (
    <Container ref={tableRef}>
      <h6 className="mb-3">نرخ خرید ارز دیجیتال و فروش ارز دیجیتال</h6>
      <Table className="text-center bg-light-blue rounded">
        <thead className="text-secondary">
          <tr>
            <td className="text-start">نام ارز</td>
            <td>قیمت(دلار)</td>
            <td>تغییرات (یک هفته)</td>
            <td>قیمت خرید (تومان)</td>
            <td>قیمت فروش (تومان)</td>
            <td>حجم بازار</td>
          </tr>
        </thead>
        <tbody className="text-dark-blue fw-bold bg-white border-white">
          {cryptos.coins?.map((item) => (
            <tr key={item._id}>
              <td className="text-start">
                <div className="w-fit d-flex flex-center col-gap-2">
                  <img
                    width="35"
                    height="35"
                    className="object-fit-contain"
                    src={item.image}
                    alt={item.name}
                  />
                  <p>
                    <span className="text-dark-blue">{item.persianName}</span>
                    <br />
                    <span className="text-secondary fs-7">
                      {item.name}~
                      <span className="text-uppercase">{item.symbol}</span>
                    </span>
                  </p>
                </div>
              </td>
              <td dir="ltr" className="white-space-pre-wrap">
                $ {item.priceUsdt}
                {"\n"}
                {showChangeValue(item.change24h?.toFixed(2))}
              </td>
              <td dir="ltr">{showChangeValue(item.change7d?.toFixed(2))}</td>
              <td>{Number(item.buyPriceToman.toFixed(5)).toLocaleString()}</td>
              <td>{Number(item.sellPriceToman.toFixed(5)).toLocaleString()}</td>
              <td>${Number(item.marketCap).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        activePage={activePage}
        setActivePage={(page) => {
          setActivePage(page);
          const top = tableRef.current?.offsetTop - 100;
          window.scrollTo({ left: 0, top });
        }}
        totalPages={cryptos.totalPages}
      />
    </Container>
  );
}
