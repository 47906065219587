import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router";
import { Container } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import navbarLogo from "../../assets/logos/logo-text.svg";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import DownloadSection from "./DownloadSection";
import Footer from "./Footer";
export default function Landing() {
  const location = useLocation();
  const [showSidebar, setShowSidebar] = useState(false);
  const handleShowSidebar = () => {
    setShowSidebar(false);
  };
  useEffect(handleShowSidebar, [location.pathname]);
  return (
    <div className="Landing">
      <header>
        <Container className="d-flex align-items-center w-100 gap-5 p-1">
          <Link to="/" className="d-none d-lg-block">
            <img width={"100px"} src={navbarLogo} alt="navbarLogo" />
          </Link>
          <i
            onClick={() => setShowSidebar(true)}
            className="d-block d-lg-none fs-1 bi bi-list"
          />
          <Navbar />
          <Sidebar show={showSidebar} onHide={setShowSidebar} />
          <NavLink to="/login" className="btn btn-violet me-auto">
            ورود / ثبت نام
          </NavLink>
        </Container>
      </header>
      <main>
        <Outlet />
      </main>
      <Container className="mt-5">
        <DownloadSection />
        <Footer />
      </Container>
    </div>
  );
}
