export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case "SET_PROFILE":
      return action.data;
    case "SET_PROFILE_IMAGE":
      return { ...state, profilePic: action.image };
    case "DECREMENT_SCORE":
      return { ...state, score: state.score - action.score };
    case "DECREMENT_CREDIT":
      return { ...state, credit: state.credit - action.credit };
    case "UPDATE_AUTH_STATUS":
      return { ...state, authentication: action.status };
    default:
      return state;
  }
}
