import { useState } from "react";
import { cities as allCities, states } from "../constants";
export default function useGetCities() {
  const [cities, setCities] = useState([]);
  const getCities = (id = "") => {
    if (!Boolean(id)) return;
    const provinceId = states.find((e) => e.name === id)?.provinceId;
    const cities = allCities.filter((e) => e.provinceId === provinceId);
    setCities(cities);
  };
  return [cities, getCities];
}
