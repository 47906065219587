import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { footer, socialNetworks } from "../../constants";
import { axios } from "../../boot";
export default function Footer() {
  const [blogList, setBlogList] = useState([]);
  const getBlogList = () => {
    const url = "/blog/getPosts";
    const params = { limit: 3 };
    axios.get(url, { params }).then(({ data }) => {
      const blogList = data.posts.map((e) => ({
        link: `/blog/${e._id}`,
        locale: true,
        ...e,
      }));
      setBlogList(blogList);
    });
  };
  const footerItems = footer.map((e, i) => {
    i === 0 && (e.children = blogList);
    return e;
  });
  useEffect(getBlogList, []);

  return (
    <footer className="mt-4">
      <Row className="align-items-start">
        {footerItems.map((item, index) => (
          <Col key={index} xs="12" md="6" lg="3">
            <h5 className="text-dark">{item.title}</h5>
            <ul>
              {item.children.map((item, index) => (
                <li className="text-dark lh-lg" key={index}>
                  {item.locale ? (
                    <Link to={item.link} state={item}>
                      {item.title}
                    </Link>
                  ) : (
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={item.link}
                      className="d-flex flex-center w-fit col-gap-1"
                    >
                      {"icon" in item && <i className={`bi bi-${item.icon}`} />}
                      {item.title}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </Col>
        ))}
        <Col className="d-flex flex-column" xs="12" md="6" lg="3">
          <h5 className="mb-4">شبکه های اجتماعی</h5>
          <div className="d-flex gap-4 text-dark fs-5">
            {socialNetworks.map((e) => (
              <a key={e.name} href={e.href}>
                <i className={`${e.icon}`}></i>
              </a>
            ))}
          </div>
        </Col>
        <div className="h6 m-0 text-center w-100 py-3 mt-3 border-top border-secondary text-secondary col-12">
          <p>تمامی حقوق سایت متعلق به پیشرو پرداخت می‌باشد.</p>
        </div>
      </Row>
    </footer>
  );
}
