import React, { useEffect, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import {
  BackBtn,
  Button,
  CheckBox,
  Form,
  Input,
  NeedAuth,
  Select,
} from "../../../components";
import { axios } from "../../../boot";
import StepIndex from "./StepIndex";
import { Link } from "react-router-dom";
import { rules } from "../../../constants";
import { toast } from "../../../methods";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
export default function BuyCrypto() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authentication = useSelector((s) => s.profile?.authentication ?? 0);
  const crypto = location.state ?? {};
  const [data, setData] = useState({
    mem: "",
    buyPriceToman: crypto.buyPriceToman,
    coinsymbol: crypto.symbol,
    discount: 0,
  });
  const [bankCards, setBankCards] = useState([]);
  const [cryptoWallets, setCryptoWallets] = useState([]);
  const [enabledBtn, setEnabledBtn] = useState(false);
  const [discountCode, setDiscountCode] = useState("");
  const getCryptoWallet = () => {
    const url = "/wallet/get_wallets";
    const body = { symbol: crypto.symbol };
    axios.post(url, body).then((res) => {
      const data = res.data.map((item) => ({
        name: item.name,
        id: item._id,
        fee: item.fee,
      }));
      setCryptoWallets(data);
    });
  };
  const getBankCards = () => {
    const url = "/creditcards/get_cards";
    axios.post(url).then(({ data }) => {
      setBankCards(
        data.map((e) => ({
          id: e._id,
          name: `${e.bankName} - (${e.cardNumber})`,
        }))
      );
    });
  };
  const handleSetValue = (key = "", val = "") => {
    const prices = {};
    const buyPrice = crypto.buyPriceToman;
    const fee = data.fee;
    if (key === "networkid") {
      const { fee } = cryptoWallets.find((e) => e.id === val);
      setData((p) => ({ ...p, pay: "", coinAmount: "", fee }));
    }
    if (key === "coinAmount") {
      +val === 0 ? (prices.pay = 0) : (prices.pay = (+val + +fee) * buyPrice);
    }
    if (key === "pay") {
      +val === 0
        ? (prices.coinAmount = 0)
        : (prices.coinAmount = +val / buyPrice - +fee);
    }
    setData((p) => ({ ...p, ...prices, [key]: val }));
  };
  const submitDiscountCode = () => {
    const url = "/user/verifyDiscountCode";
    const body = {
      code: discountCode,
    };
    axios.post(url, body).then(({ data }) => {
      toast({ text: data.message });
      const discount = data.discountCode?.discount ?? 0;
      setDiscountCode("");
      setData((p) => ({ ...p, discount }));
    });
  };
  const submit = () => {
    const url = `/order/${data.paymentType}`;
    const body = { ...data };
    delete body.paymentType;
    Object.keys(body).forEach((key) => {
      body[key] = String(body[key]);
    });
    axios.post(url, body).then((res) => {
      if (data.paymentType === "addonlinebuyOrder") {
        const text = "در حال انتقال به درگاه پرداخت";
        toast({ text });
        window.location.href = res.data.link;
        return;
      }
      const text = "سفارش شما با موفقیت ثبت شد.";
      toast({ text });
      dispatch({ type: "DECREMENT_CREDIT", credit: +data.pay });
      navigate("/dashboard");
    });
  };
  const formControls1 = [
    {
      tag: Select,
      label: "نوع شبکه",
      items: cryptoWallets,
      state: "networkid",
      append: data.networkid && (
        <i className="input-group-text fs-7 bg-transparent">{`کارمزد = ${data.fee} ${crypto.symbol}`}</i>
      ),
      rules: rules.required,
    },
    {
      label: "مقدار ارز درخواستی",
      state: "coinAmount",
      rules: rules.required,
      type: "number",
      disabled: !data.networkid,
    },
    {
      label: "میزان پرداختی",
      state: "pay",
      type: "price",
      rules: rules.buyPrice,
      append: <i className="input-group-text">تومان</i>,
      disabled: !data.networkid,
    },
  ];
  const formControls2 = [
    data.networkid && {
      label: "تگ ریپل، ممو استلار، پیمنت آیدی(در صورت موجود بودن)",
      state: "mem",
      rules: [() => true],
    },
    {
      label: "آدرس کیف پول خود را وارد کنید",
      state: "userWallet",
      rules: rules.required,
    },
    {
      label: "توضیحات",
      state: "userDecription",
      rules: [() => true],
    },
  ].filter((e) => e);
  const formControls3 = [
    {
      tag: Select,
      label: "انتخاب شماره کارت",
      state: "cardid",
      rules: rules.required,
      items: bankCards,
    },
    {
      tag: Select,
      label: "انتخاب نوع پرداخت",
      state: "paymentType",
      rules: rules.required,
      items: [
        { name: "درگاه پرداخت زرین پال", id: "addonlinebuyOrder" },
        { name: "خرید آفلاین با استفاده از اعتبار", id: "addofflinebuyOrder" },
      ],
    },
  ];
  const formControls = [formControls1, formControls2, formControls3];
  const totalPayable = ((data.pay ?? 0) * (100 - data.discount)) / 100;
  useEffect(getBankCards, []);
  useEffect(getCryptoWallet, []);
  return (
    <div className="BuyCrypto wrapper">
      <div>
        <BackBtn />
      </div>
      <NeedAuth />
      <div className="cryptoSymbol d-flex flex-column flex-center">
        <img
          width="75"
          height="75"
          className="object-fit-contain"
          src={crypto.image}
          alt={crypto.name}
        />
        <h6 className="fw-bold">
          {crypto.name}{" "}
          <span className="text-uppercase">({crypto.symbol})</span>
        </h6>
        <p>{Number(crypto.buyPriceToman.toFixed(5)).toLocaleString()} تومان</p>
      </div>
      <Form onSubmit={submit} className="row justify-content-start">
        <Col xs="12">
          <StepIndex index={1} />
        </Col>
        {formControls[0].map((item, index) => (
          <Col key={index} xs="12" lg="6">
            {React.createElement(item.tag ?? Input, {
              ...item,
              value: data[item.state],
              setValue: (val) => handleSetValue(item.state, val),
            })}
          </Col>
        ))}
        <Col xs="12">
          <StepIndex index={2} />
        </Col>
        <Col xs="12">
          <Alert variant="info">
            <p className="text-info">
              <i className="bi bi-exclamation-circle-fill fs-5 ms-1"></i>
              در وارد نمودن آدرس کیف‌پول خود دقت فرمائید و از آدرس کیف پول معتبر
              استفاده کنید . مسئولیت و عواقب آدرس کیف پول نامعتبر یا شبکه
              نامرتبط به عهده خریدار میباشد.
            </p>
          </Alert>
        </Col>
        {formControls[1].map((item) => (
          <Col key={item.state} xs="12" lg="6">
            {React.createElement(item.tag ?? Input, {
              ...item,
              value: data[item.state],
              setValue: (val) => setData((p) => ({ ...p, [item.state]: val })),
            })}
          </Col>
        ))}
        <Col xs="12">
          <StepIndex index={3} />
        </Col>
        <Col xs="12" className="d-flex col-gap-1">
          <Alert variant="info" className="text-start">
            <p className="text-info">
              <i className="bi bi-exclamation-circle-fill fs-5 ms-1"></i>
              در وارد نمودن آدرس کیف‌پول خود دقت فرمائید و از آدرس کیف پول معتبر
              استفاده کنید . مسئولیت و عواقب آدرس کیف پول نامعتبر یا شبکه
              نامرتبط به عهده خریدار میباشد.
            </p>
            <p className="text-info">
              <i className="bi bi-exclamation-circle-fill fs-5 ms-1"></i>
              شما میتوانید فقط با کارتهای بانکی خودتان خرید نمایید. در صورت خرید
              با کارت اشخاص دیگر سفارش شما مسدود خواهد شد.
            </p>
            <p className="text-info">
              <i className="bi bi-exclamation-circle-fill fs-5 ms-1"></i>
              حساب بانکی و حساب کاربری خود را در اختیار شخص دیگری قرار ندهید.
              مسئولیت خرید با پول های نامشروع کاملا بر عهده شما خواهد بود.
            </p>
          </Alert>
        </Col>
        {formControls[2].map((item, index) => (
          <Col key={index} xs="12" lg="6">
            {React.createElement(item.tag ?? Input, {
              ...item,
              value: data[item.state],
              setValue: (val) => setData((p) => ({ ...p, [item.state]: val })),
            })}
          </Col>
        ))}
        <Col xs="12">
          <Row className="payment-section bg-primary bg-opacity-10 border border-primary rounded py-3 px-2 mb-3">
            <Col xs="12" lg="6" className="d-flex flex-column flex-center px-0">
              <CheckBox
                setValue={setEnabledBtn}
                value={enabledBtn}
                className="my-auto w-fit"
                variant="violet"
                label={
                  <>
                    <Link
                      to="/"
                      className="text-violet text-decoration-underline fw-bold"
                    >
                      شرایط خرید
                    </Link>{" "}
                    سایت پیشرو پرداخت را مطالعه و پذیرفتم.
                  </>
                }
              />
              <Button
                disabled={!enabledBtn || authentication !== 1}
                type="submit"
                variant="violet"
                className="w-75 mt-auto"
              >
                پرداخت
              </Button>
            </Col>
            <Col xs="12" lg="6" className="d-flex flex-column flex-center px-0">
              <p className="h6 text-dark-blue fw-bold text-center my-auto">
                هزینه قابل پرداخت:
                <br />
                <span className="fs-5">
                  {totalPayable.toLocaleString() ?? 0} تومان
                </span>
              </p>
              <Input
                value={discountCode}
                setValue={setDiscountCode}
                label="کد تخفیف:"
                append={
                  <Button
                    onClick={submitDiscountCode}
                    variant="primary"
                    className="text-white"
                  >
                    اعمال
                  </Button>
                }
              />
            </Col>
          </Row>
        </Col>
      </Form>
    </div>
  );
}
