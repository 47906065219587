import React from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useParams } from "react-router";
import { moment } from "../../../boot";
import { payStatus } from "../../../constants";

export default function Receipt() {
  const param = useParams();
  const location = useLocation();
  const data = location.state ?? {};
  const isBuy = param.id === "buy";
  const isSell = param.id === "sell";
  const userType = isSell ? "مشتری" : "گیرنده";
  const showStatus = (status = 0) => {
    const { color, name } = payStatus.find((e) => e.id === status);
    return <span className={`w-fit text-${color}`}>{name}</span>;
  };
  const formControls = [
    {
      label: "نام ارز",
      value: `${data.coin?.name} ~ ${data.coin?.persianName}`,
    },
    {
      label: `نام ${userType}`,
      value: data.user?.name,
    },
    {
      label: "مقدار",
      value: data.coinAmount,
    },
    {
      label: `موبایل ${userType}`,
      value: data.user?.phone,
    },
    {
      label: `ایمیل ${userType}`,
      value: data.user?.email,
    },
    {
      label: "قیمت ارز (تومان)",
      value: (isBuy
        ? data.coin?.buyPriceToman
        : data.coin.sellPriceToman
      )?.toLocaleString(),
    },
    isBuy && {
      label: "آدرس کیف پول گیرنده",
      value: data.network?.address,
    },
    isBuy && {
      label: "آدرس کیف پول",
      value: data.userWallet,
    },
    isSell && {
      label: "آدرس کیف پول پیشرو",
      value: data.weWallet,
    },
    isSell && {
      label: "مقدار TXID",
      value: data.txid,
    },
    // {
    //   label: "نوع پرداخت",
    //   value: "",
    // },
    isSell && {
      label: "تاریخ فروش",
      value: moment.miladiToShamsi({ date: data.createdAt }),
    },
    isBuy && {
      label: "تاریخ خرید",
      value: moment.miladiToShamsi({ date: data.createdAt }),
    },
    {
      label: "شماره حساب",
      value: data.creditCard?.hesabNumber,
    },
    {
      label: "شماره کارت",
      value: data.creditCard?.cardNumber,
    },
    {
      label: "شماره شبا",
      value: data.creditCard?.shebaNumber,
    },
    {
      label: "وضعیت سفارش",
      value: showStatus(data[isBuy ? "orderStatus" : "receiveStatus"]),
    },
    isSell && {
      label: "وضعیت تسویه",
      value: data.payStatus ? (
        <span className="text-success">تسویه شده</span>
      ) : (
        <span className="text-danger">تسویه نشده</span>
      ),
    },
    isSell && {
      label: "جمع کل قابل پرداخت (تومان)",
      value: (
        <span className="text-success">
          {Number(data.totalPayable).toLocaleString()}
        </span>
      ),
    },
    isBuy && {
      label: "جمع نهایی (تومان)",
      value: (
        <span className="text-success">
          {Number(data.pay).toLocaleString()}
        </span>
      ),
    },
  ].filter(Boolean);
  return (
    <Row className="wrapper">
      <Col
        xs="12"
        md="10"
        lg="8"
        xl="6"
        className="rounded bg-primary bg-opacity-10 border border-primary px-0"
      >
        {formControls.map((e, i) => (
          <React.Fragment key={e.label}>
            {!!i && <hr className="bg-light" />}
            <div className="d-flex align-items-center justify-content-between px-4 py-2 my-1">
              <span className="text-secondary">{e.label}:</span>{" "}
              <span className="fw-bold">{e.value}</span>
            </div>
          </React.Fragment>
        ))}
      </Col>
    </Row>
  );
}
