import { useRef } from "react";
import { useSelector } from "react-redux";
import authImg from "../../../assets/images/auth img.svg";
import { Button } from "../../../components";

export default function Step5({ data, setData, submit }) {
  const profile = useSelector((s) => s.profile);
  const inputRef = useRef(null);
  const showAuthImg = () => {
    if (!data.image) return authImg;
    const src = URL.createObjectURL(data.image);
    return src;
  };
  return (
    <div className="d-flex flex-column gap-3">
      <p>
        <span className="text-danger">*</span> در این مرحله کاربر باید یک عکس در
        سامانه پیشرو بارگذاری نماید، که این عکس شامل تصویر خود کاربر باشد در
        حالی که در یک دست خود متن تعهدنامه زیر را بصورت{" "}
        <span className="fw-bold">دست‌نویس</span> همراه با کارت ملی و کارت بانکی
        خود در دست گرفته است.
      </p>
      <p>
        <span className="text-warning">متن تعهدنامه :</span> اینجانب
        {` "${profile.name}" `}
        با کد ملی <b>"کد ملی"</b> و به شماره شناسنامه <b>"شماره شناسنامه"</b>{" "}
        ضمن مطالعه و تایید قوانین سایت پیشرو، ضمن تایید صحت اطلاعات ثبت شده در
        سایت سامانه پیشرو متعهد می‌شوم که حساب کاربری ثبت شده در سایت پیشرو را
        عمدا یا سهوا در اختیار فرد یا موسسه دیگر قرار ندهم در غیر این صورت،
        مسئولیت کیفری و حقوقی هر گونه جرم یا تخلف صورت گرفته بر عهده اینجانب است
        .این متن جهت احراز هویت و تعهد برای سایت پیشرو تهیه و تنظیم شده است.
        تاریخ روز امضا
      </p>
      <p>
        <span className="text-danger">*</span> از ایجاد تغییرات در عکس بوسیله
        نرم‌افزارهای ویرایش عکس خودداری کنید.
      </p>
      <p>
        <span className="text-danger">*</span> عکس خود را مشابه نمونه زیر در
        سایت ارسال کنید.
      </p>
      <p>
        <span className="text-danger">*</span> لطفا هنگام گرفتن عکس از کارت
        بانکی، تاریخ انقضا و cvv2 را بپوشانید
      </p>
      <img
        width="250"
        height="300"
        src={showAuthImg()}
        alt="auth"
        className="object-fit-contain rounded mx-auto"
      />
      <Button
        className="d-block mx-auto text-white"
        onClick={() => inputRef.current.click()}
      >
        آپلود عکس
      </Button>
      <input
        ref={inputRef}
        type="file"
        className="d-none"
        accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff"
        onChange={({ target }) =>
          setData((p) => ({ ...p, image: target.files[0] }))
        }
      />
      {data.image && (
        <Button onClick={submit} variant="violet" className="mx-auto">
          تایید اطلاعات
        </Button>
      )}
    </div>
  );
}
