import React from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { moment } from "../../../boot";
export default function BlogDetail() {
  const location = useLocation();
  const blogDetail = location.state ?? {};
  const { title, content, image, createdAt } = blogDetail;
  return (
    <Container className="mt-5 d-flex flex-column gap-5">
      <h2 className="text-center w-100">{title}</h2>
      <img
        style={{ maxWidth: "525px" }}
        className="d-block mx-auto w-100 shadow rounded"
        src={image}
        alt={title}
      />
      <p className="fs-5 text-justify w-100 white-space-pre-wrap">{content}</p>
      <span className="w-fit text-gray me-auto fs-7">
        {moment.miladiToShamsi({ date: createdAt })}
        <i className="bi bi-calendar3 fs-6 me-1" />
      </span>
    </Container>
  );
}
