import { Col, Row } from "react-bootstrap";
import { downloadItems } from "../../constants";

export default function DownloadSection() {
  return (
    <div className="row text-center bg-primary bg-opacity-50 rounded-3 p-5 row-gap-5 rounded-10">
      <p className="h1 col-12 text-dark">دانلود اپلیکیشن پیشرو پرداخت</p>
      <Col xs="12" md="6" lg="5">
        <Row>
          {downloadItems.map((item, index) => (
            <Col key={index} xs="6" md="4">
              <a
                href="https://google.com"
                target="_blank"
                rel="noreferrer"
                className="d-block w-100"
              >
                <img className="w-100" src={item.img} alt={item.img} />
              </a>
            </Col>
          ))}
        </Row>
      </Col>
    </div>
  );
}
