import { useEffect, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

export default function SearchBox() {
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState("");
  const [queryParam, setQueryParams] = useSearchParams();
  const searchPages = ["/buy-cryptos", "/sell-cryptos"];
  const isInsidePage = searchPages.includes(location.pathname);
  const querySearch = queryParam.get("search");
  const hasSearch = !!querySearch;
  const clearSearch = () => {
    setSearch("");
    setQueryParams({}, { replace: true });
  };
  const handleChangeLocation = () => {
    if (isInsidePage) return;
    setSearch("");
  };
  const submit = (e) => {
    e.preventDefault();
    const to = {
      search: !!search ? `search=${search}` : null,
      pathname: !isInsidePage ? "/buy-cryptos" : undefined,
    };
    navigate(to, { replace: true });
  };
  useEffect(handleChangeLocation, [location.pathname]);
  useEffect(() => setSearch(querySearch), [location.search]);
  return (
    <InputGroup onSubmit={submit} as="form" className="flex-row-reverse">
      {hasSearch && (
        <InputGroup.Text
          as="button"
          type="button"
          className="bi bi-x-lg fs-5 text-danger"
          onClick={clearSearch}
        />
      )}
      <FormControl
        placeholder="جستجو کنید ..."
        value={search ?? ""}
        onChange={({ target }) => setSearch(target.value)}
      />
      <InputGroup.Text
        as="button"
        type="submit"
        className="d-flex flex-center bi bi-search fs-5 text-secondary"
      />
    </InputGroup>
  );
}
