import { axios } from "../../boot";
import { useDispatch } from "react-redux";

import { Button, Modal } from "../../components";
export default function LogoutModal({ show = true, onHide = () => {} }) {
  const dispatch = useDispatch();
  const setIsLogged = (data) => {
    dispatch({ type: "SET_IS_LOGGED", data });
  };
  const logout = () => {
    localStorage.removeItem("token");
    delete axios.defaults.headers.token;
    setIsLogged(false);
  };
  return (
    <Modal show={show} onHide={onHide} variant="danger" title="خروج از حساب">
      <h6 className="text-center mb-5">آیا از درخواست خود مطمئن هستید؟</h6>
      <div className="w-100 d-flex algin-items-center justify-content-evenly">
        <Button
          variant="success"
          className="text-white px-4"
          onClick={() => logout()}
        >
          بله
        </Button>
        <Button
          variant="danger"
          className="text-white px-4"
          onClick={() => onHide(false)}
        >
          خیر
        </Button>
      </div>
    </Modal>
  );
}
