import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { Button, CreditOnline, Modal, Table } from "../../../components";
import { axios, moment } from "../../../boot";
import { creditStatus } from "../../../constants";
export default function IncreaseCreditOnline() {
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [payList, setPayList] = useState([]);
  const [bankCards, setBankCards] = useState([]);
  const [ rejectText , setRejectText ] = useState("")
  
  const getPayList = () => {
    const url = "/pay/getUserOnlinePay";
    axios.post(url).then(({ data }) => {
      setPayList(data.pays);
    });
  };
  const getBankCards = () => {
    const url = "/creditcards/get_cards";
    axios.post(url).then(({ data }) => {
      setBankCards(
        data.map((e) => ({
          id: e._id,
          name: `${e.bankName} - (${e.cardNumber})`,
        }))
      );
    });
  };
  const showStatus = (status = 0) => {
    const { color, name } = creditStatus.find((e) => e.id === status);
    return (
      <Alert variant={color} className="w-fit border-0 p-2 mx-auto">
        {name}
      </Alert>
    );
  };
  useEffect(getPayList, []);
  useEffect(getBankCards, []);
  return (
    <div className="IncreaseCreditOnline">
      <Modal show={showModal} onHide={setShowModal}>
        <CreditOnline cards={bankCards} />
      </Modal>

      <Modal title="علت رد شدن" variant="danger" show={showModal2} onHide={setShowModal2}>
        <p className="text-center">{rejectText}</p>
      </Modal>
      <div className="bg-white px-3 py-3 shadow-sm rounded-5">
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="text-violet">افزایش اعتبار آنلاین</h5>
          <Button
            onClick={() => setShowModal(true)}
            className="btn btn-violet rounded-5 px-4 py-2"
          >
            افزایش اعتبار آنلاین
          </Button>
        </div>
        <Alert variant="danger" className="mt-3">
          <i className="bi bi-exclamation-triangle-fill fs-5 ms-1" />
          مبالغی که از طریق درگاه بانکی به اعتبار شما اضافه میشود فقط بابت
          پرداخت سفارشات شما قابل استفاده است و با توجه به محدودیت های بانکی
          قابل برداشت نیست
        </Alert>
        <Table className="mt-3 text-center text-secondary bg-light-blue rounded-5">
          <thead>
            <tr>
              <td>#</td>
              <td>شناسه</td>
              <td>مبلغ(تومان)</td>
              <td>وضعیت</td>
              
              <td>آخرین بروزرسانی</td>
            </tr>
          </thead>
          <tbody className="bg-white border-white">
            {payList.map((e, i) => (
              <tr key={e._id}>
                <td>{i + 1}</td>
                <td>{e.paymentCode}</td>
                <td>{Number(e.amount).toLocaleString()}</td>
                <td onClick={()=>{if(e.status == 2){setShowModal2(true);};setRejectText(e.adminDecription) }}>{showStatus(e.status)}</td>
                {/* <td>{e.adminDecription}</td> */}
                <td dir="ltr">
                  {moment.miladiToShamsi({ date: e.updatedAt })}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
