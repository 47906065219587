const footer = [
  // {
  //   title: "خدمات پیشرو",
  //   children: [
  //     {
  //       title: "ویندوز سرور ترید",
  //       link: "",
  //     },
  //     {
  //       title: "خدمات vip مشتریان",
  //       link: "",
  //     },
  //     {
  //       title: "خدمات باینانس",
  //       link: "",
  //     },
  //   ],
  // },
  {
    title: "آخرین مقالات",
    children: [],
  },
  {
    title: "اطلاعات تماس",
    children: [
      {
        title: "info@marveltrade.org",
        icon: "envelope-fill",
        link: "",
      },
      {
        title: "021-28424778",
        icon: "telephone-outbound-fill",
        link: "",
      },
      {
        title: "Marvel_Tradee@",
        icon: "instagram",
        link: "",
      },
    ],
  },
];
export default footer;
