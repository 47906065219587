import { useDispatch } from "react-redux";
import { axios } from "../boot";
export default function useSetToken() {
  const dispatch = useDispatch();
  const setIsLogged = (data) => dispatch({ type: "SET_IS_LOGGED", data });
  return (token = "") => {
    const value = `Bearer ${token}`;
    localStorage.setItem("token", value);
    axios.defaults.headers.Authorization = value;
    setIsLogged(true);
  };
}
