import React from 'react'
import { Accordion } from "../../../components";
import { useSeenMessage  } from "../../../hooks";


const MessAccordion = (
    {
        item = {},
        color = ""
    }
) => {
    const { setNewId } = useSeenMessage()

    return (
        <Accordion onClick={()=>{setNewId(item?._id)}} key={item?._id} title={item?.title} variant={color}>
            <p>{item?.content}</p>
        </Accordion>
    )
}

export default MessAccordion