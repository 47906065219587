import "./index.scss";
export default function CheckBox({
  label = null,
  variant = "primary",
  className = "",
  value = false,
  setValue = () => {},
  // name = "",
  // value = "",
  // option = "",
  // setValue = () => {},
}) {
  return (
    <div className={`CheckBox ${className}`}>
      <label className="d-flex align-items-center col-gap-1">
        <input
          className="d-none"
          type="checkbox"
          checked={!!value}
          onChange={() => setValue(!value)}
        />
        <div className={`position-relative rounded-1 border border-${variant}`}>
          <div
            className={`bi bi-check position-absolute d-flex flex-center transition fs-4 text-white bg-${variant}`}
          />
        </div>
        {label && <span>{label}</span>}
      </label>
    </div>
  );
}
