import { useNavigate } from "react-router";

export default function BackBtn() {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate(-1)}
      className="d-flex flex-center text-violet me-auto"
    >
      بازگشت
      <i className="bi bi-arrow-left"></i>
    </button>
  );
}
