import React, { useEffect, useRef, useState } from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import { replaceNonDigits, showFileName, showNumber } from "../../methods";
import { Modal, Button } from "..";
import { Calendar } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import "./index.scss";
import { moment } from "../../boot";
export default function Input({
  label = "",
  value = "",
  setValue = () => {},
  onClick = () => {},
  rules = [],
  type = "text",
  append = "",
  prepend = "",
  as = "input",
  placeholder = null,
  readOnly = false,
  minDate = null,
  maxDate = null,
  dir = "rtl",
  accept = "",
  disabled = false,
}) {
  const input = useRef();
  const inputFileRef = useRef();
  const message = useRef();
  const [focused, setFocused] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);

  const isPrice = type === "price";
  const isDate = type === "date";
  const isFile = type === "file";
  const hasFile = isFile && !!value.length;
  const checkValid = () => {
    input.current.isValid = () => {
      return rules.every((rule) => {
        const isValid = rule(value ?? "");
        if (isValid !== true) {
          message.current.innerText = isValid;
          return false;
        }
        message.current.innerText = "";
        return true;
      });
    };
  };
  const handleFocused = () => {
    input.current.classList.toggle("focused", focused);
  };
  const handleFormatDate = ({ day, month, year }) => {
    const date = `${year}/${showNumber(month.number)}/${showNumber(day)}`;
    return new Date(moment.shamsiToMiladi({ date }));
  };
  const handleChange = (e) => {
    const value = e.target?.value;
    if (isFile) return;
    if (isPrice) return setValue(replaceNonDigits(value));
    if (isDate) return setValue(handleFormatDate(e));
    setValue(value);
  };
  const handleFocus = () => {
    isDate && setShowCalendar(true);
    setFocused(true);
  };
  const handleBlur = () => {
    setFocused(false);
  };
  const handleReadOnly = () => {
    if (isDate || isFile) return true;
    return readOnly || disabled;
  };
  const handleValue = () => {
    if (!Boolean(value)) return "";
    if (isDate) return moment.miladiToShamsi({ date: value });
    if (isPrice) return Math.ceil(Number(value)).toLocaleString();
    if (isFile) return showFileName(value);
    return value ?? "";
  };
  const clickInputFile = () => {
    inputFileRef.current?.click();
  };
  const removeFile = () => {
    inputFileRef.current.value = "";
    setValue(undefined);
  };

  const handleType = () => {
    if (isDate || isFile) return "text";
    if (isPrice) return "tel";
    return type;
  };
  useEffect(handleFocused, [focused]);
  useEffect(checkValid, [value, rules]);
  return (
    <div ref={input} className="Input position-relative w-100 check-valid">
      <span className="me-1 mb-1 d-block">{label}</span>
      <InputGroup className="input-box w-100 flex-row-reverse">
        {isFile && (
          <Button onClick={clickInputFile} variant="light-gray">
            انتخاب فایل
          </Button>
        )}
        {append}
        <FormControl
          as={as}
          dir={dir}
          type={handleType()}
          readOnly={handleReadOnly()}
          value={handleValue() ?? ""}
          placeholder={placeholder}
          onChange={handleChange}
          onClick={onClick}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className="border-0 shadow-none"
        />
        {hasFile && (
          <button
            type="button"
            className="input-group-text bi bi-x-lg text-danger"
            onClick={removeFile}
          />
        )}
        {prepend}
      </InputGroup>
      {isFile && (
        <input
          ref={inputFileRef}
          type="file"
          accept={accept}
          className="d-none"
          onChange={({ target }) => setValue(target.files[0])}
        />
      )}
      {rules.length !== 0 && (
        <p
          ref={message}
          className="message w-100 d-block text-danger text-start px-2 mb-0"
        ></p>
      )}
      {isDate && (
        <Modal title={label} show={showCalendar} onHide={setShowCalendar}>
          <Calendar
            className="mx-auto mb-3"
            calendar={persian}
            locale={persian_fa}
            format="YYYY/MM/DD"
            minDate={minDate}
            maxDate={maxDate}
            value={value ?? ""}
            onChange={handleChange}
          />
          <Button
            onClick={() => setShowCalendar(false)}
            className="d-block text-white px-4 mx-auto"
          >
            ثبت
          </Button>
        </Modal>
      )}
    </div>
  );
}
