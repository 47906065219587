import { Navigate } from "react-router";

import Landing from "../layouts/Landing";
import Users from "../layouts/Users";

import ContactUs from "../pages/Landing/ContactUs";
// import CreditCards from "../pages/Landing/CreditCards";
import Home from "../pages/Landing/Home";
import LoginLayout from "../pages/Landing/Login";
import Login from "../pages/Landing/Login/Login";
import Register from "../pages/Landing/Login/Register";
import ForgetPassword from "../pages/Landing/Login/ForgetPassword";

import BankCards from "../pages/Users/BankCards";
import Dashboard from "../pages/Users/Dashboard";
import Messages from "../pages/Users/Messages";
import MyInformation from "../pages/Users/MyInformation";
import Tickets from "../pages/Users/Tickets";
// import VisaMasterPhysicalCard from "../pages/Users/VisaMasterPhysicalCard";
// import VisaMasterCardSubmitInformation from "../pages/Users/VisaMasterCardSubmitInformation";
// import Wallet from "../pages/Users/Wallet";
import NewTicket from "../pages/Users/NewTicket";
import IncreaseCreditOnline from "../pages/Users/IncreaseCreditOnline";
import IncreaseCreditOffline from "../pages/Users/IncreaseCreditOffline";
// import WithdrawCredit from "../pages/Users/WithdrawCredit";
// import CreditChanges from "../pages/Users/CreditChanges";
import Cryptos from "../pages/Users/Cryptos";
import BuyCrypto from "../pages/Users/BuyCrypto";
import SellCrypto from "../pages/Users/SellCrypto";
import SuccessfulPurchase from "../pages/Users/SuccessfulPurchase";
import Authentication from "../pages/Users/Authentication";
import Ticket from "../pages/Users/Ticket";
import Blog from "../pages/Landing/Blog";
import BlogDetail from "../pages/Landing/BlogDetail";
import Receipt from "../pages/Users/Receipt";

const SellCryptos = () => <Cryptos />;

const routes = (isLogged = false) => [
  {
    path: "/",
    element: <Landing />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      // {
      //   path: "credit-card",
      //   element: <CreditCards />,
      // },
      {
        path: "blog",
        element: <Blog />,
      },
      {
        path: "blog/:id",
        element: <BlogDetail />,
      },
      {
        path: "contact-us",
        element: <ContactUs />,
      },
      {
        path: "login",
        element: <LoginLayout />,
        children: [
          {
            path: "",
            element: <Login />,
          },
        ],
      },
      {
        path: "register",
        element: <LoginLayout />,
        children: [
          {
            path: "",
            element: <Register />,
          },
        ],
      },
      {
        path: "forget-password",
        element: <LoginLayout />,
        children: [
          {
            path: "",
            element: <ForgetPassword />,
          },
        ],
      },
    ],
  },
  {
    path: "/",
    element: isLogged ? <Users /> : <Navigate to="/login" replace />,
    children: [
      // {
      //   path: "wallet",
      //   element: <Wallet />,
      // },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      // {
      //   path: "visa-master-physical-card",
      //   element: <VisaMasterPhysicalCard />,
      // },
      // {
      //   path: "visa-master-card-submit-information",
      //   element: <VisaMasterCardSubmitInformation />,
      // },
      {
        path: "buy-cryptos",
        element: <Cryptos />,
      },
      {
        path: "buy-cryptos/:id",
        element: <BuyCrypto />,
      },
      {
        path: "sell-cryptos",
        element: <SellCryptos />,
      },
      {
        path: "sell-cryptos/:id",
        element: <SellCrypto />,
      },
      {
        path: "buy-cryptos/success",
        element: <SuccessfulPurchase />,
      },
      {
        path: "increase-credit-online",
        element: <IncreaseCreditOnline />,
      },
      {
        path: "increase-credit-offline",

        element: <IncreaseCreditOffline />,
      },
      // {
      //   path: "withdraw-credit",
      //   element: <WithdrawCredit />,
      // },
      // {
      //   path: "credit-changes",
      //   element: <CreditChanges />,
      // },
      {
        path: "bank-cards",
        element: <BankCards />,
      },
      {
        path: "tickets",
        element: <Tickets />,
      },
      {
        path: "tickets/:id",
        element: <Ticket />,
      },
      {
        path: "new-ticket",
        element: <NewTicket />,
      },
      {
        path: "messages",
        element: <Messages />,
      },
      {
        path: "my-information",
        element: <MyInformation />,
      },
      {
        path: "authentication",
        element: <Authentication />,
      },
      {
        path: "receipt/:id",
        element: <Receipt />,
      },
    ],
  },
  {
    path: "*",
    element: (
      <p className="container h1 text-center py-4 mx-auto">
        صفحه مورد نظر یافت نشد!
      </p>
    ),
  },
];
export default routes;
