import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Table } from "../../../../../components";
import { axios, moment } from "../../../../../boot";
import { toast } from "../../../../../methods";

export default function DiscountCode({ discount = [] }) {
  const profile = useSelector((p) => p.profile);
  const dispatch = useDispatch();
  const [codes, setCodes] = useState([]);
  const createDiscount = () => {
    const text = "امتیاز کافی نیست!";
    if (profile.score < 500) return toast({ text, type: "error" });
    const url = "/user/createDiscountCode";
    axios.post(url).then(({ data }) => {
      const text = "کد تخفیف با موفقیت اضافه شد";
      setCodes((p) => [...p, data.discountCode]);
      dispatch({ type: "DECREMENT_SCORE", score: 500 });
      toast({ text });
    });
  };
  const showStatus = (expire = false) => {
    const props = {
      label: expire ? "منثضی شده" : "قابل استفاده",
      variant: expire ? "danger" : "success",
    };
    return <Badge {...props} />;
  };
  return (
    <Col xs="12" md="6">
      <div className="wrapper">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h5 className="text-violet m-0">کد تخفیف</h5>
          <div className="d-flex flex-center col-gap-2">
            <p className="text-primary fs-7">
              دریافت کد تخفیف با کسر 500 امتیاز
            </p>
            <Button onClick={createDiscount} className="text-white">
              دریافت کد
            </Button>
          </div>
        </div>
        <Table>
          <thead className="text-secondary">
            <tr>
              <td>کد</td>
              <td>وضعیت</td>
              <td>تاریخ ایجاد</td>
            </tr>
          </thead>
          <tbody>
            {[...codes, ...discount].map((e) => (
              <tr key={e._id}>
                <td>{e.code}</td>
                <td>{showStatus(e.expire)}</td>
                <td>{moment.miladiToShamsi({ date: e.createdAt })}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Col>
  );
}
