import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import payFast from "../../../../../../src/assets/images/service/1.svg";
import payPal from "../../../../../../src/assets/images/service/2.svg";
import buyAndSale from "../../../../../../src/assets/images/service/3.svg";
import cash from "../../../../../../src/assets/images/service/4.svg";
import masterCard from "../../../../../../src/assets/images/service/5.svg";
import giftCard from "../../../../../../src/assets/images/service/6.svg";

const services = [
  {
    image: payFast,
    title: "پرداخت ارزی",
    label: "پرداخت با ویزا کارت و مسترکارت\nدر سایت‌های خارجی",
  },
  {
    image: payPal,
    title: "پی پال        ",
    label: "خرید، فروش و افتتاح\nپی پال",
  },
  {
    image: buyAndSale,
    title: "خرید و فروش رمز ارزها",
    label: "خرید و فروش بیت کوین، اتریوم\nو تمامی ارزهای دیجیتالی",
  },
  {
    image: cash,
    title: "نقد کردن درآمد ارزی        ",
    label: "درآمد دلاری و بین المللی\nکسب کن",
  },
  {
    image: masterCard,
    title: "صدور انواع مستر کارت        ",
    label: "خرید انواع مستر کارت‌های\nفیزیکی و مجازی",
  },
  {
    image: giftCard,
    title: "خرید گیفت کارت        ",
    label: "گیفت کارت‌های اپل، استیم,\nاسپاتیفای، نتفلیکس و...",
  },
];

export default function Services() {
  return (
    <div className="services">
      <Container>
        <Row>
          <h4 className="fw-bold text-center ">خدمات پیشرو پرداخت</h4>
          {services.map((item, index) => (
            <Col key={index} xs="12" md="6" lg="4">
              <div
                style={{ height: "225px" }}
                className="d-flex flex-center text-center flex-column bg-white overflow-hidden rounded shadow-sm p-3 row-gap-1"
              >
                <img
                  height={90}
                  className="object-fit-contain"
                  src={item.image}
                  alt={item.title}
                />
                <h1 className="h6 text-primary lh-normal m-0">{item.title}</h1>
                <p className="white-space-pre-wrap">{item.label}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}
