import { axios } from "../boot";
import { filesBaseUrl } from "../constants/_server";
import showFileName from "./_showFileName";
export default function downloadFile(fileName = "") {
  const url = `${filesBaseUrl}${fileName}`;
  axios.get(url, { responseType: "blob" }).then(({ data }) => {
    const href = window.URL.createObjectURL(new Blob([data]));
    const a = document.createElement("a");
    a.href = href;
    a.download = showFileName(fileName);
    a.click();
  });
}
