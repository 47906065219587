import { Col } from "react-bootstrap";
import { CreditOnline } from "../../../../../components";

export default function IncreaseCreditOnlineSection({ cards = [] }) {
  return (
    <Col xs="12" lg="6">
      <div className="wrapper">
        <CreditOnline cards={cards} />
      </div>
    </Col>
  );
}
