import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { Button, Form, Input, Select } from "../../components";
import { rules } from "../../constants";
import { axios } from "../../boot";

export default function CreditOnline({ cards = [] }) {
  const [step, setStep] = useState(0);
  const [data, setData] = useState({});
  const submitStep = () => setStep(1);
  const submitForm = () => {
    const url = "/pay/addOnlinePay";
    const body = data;
    axios.post(url, body).then(({ data }) => {
      window.location.href = data.link;
    });
  };
  return (
    <Form
      onSubmit={step === 0 ? submitStep : submitForm}
      className="d-flex flex-column row-gap-3"
    >
      <h5 className="text-violet">افزایش اعتبار آنلاین</h5>
      <Input
        value={data.amount}
        setValue={(amount) => setData((p) => ({ ...p, amount }))}
        placeholder="مبلغ به تومان وارد شود"
        type="price"
        rules={rules.creditOnline}
        append={
          <Button type="submit" size="md" variant="violet">
            افزایش اعتبار آنلاین
          </Button>
        }
      />
      {step === 1 && (
        <React.Fragment>
          <Select
            value={data.card}
            setValue={(card) => setData((p) => ({ ...p, card }))}
            label="انتخاب کارت"
            items={cards}
            rules={rules.required}
          />
          <Alert variant="danger" className="fs-7">
            کاربر گرامی!
            <br />
            مبالغی که از طریق درگاه بانکی به اعتبار شما اضافه می‌شود فقط بایت
            پرداخت سفارشات شما قابل استفاده است و با توجه به محدودیت‌های بانکی
            قابل برداشت نیست
          </Alert>
          <Button type="submit" className="text-white mt-auto">
            پرداخت آنلاین
          </Button>
        </React.Fragment>
      )}
    </Form>
  );
}
