import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useLocation, useParams } from "react-router";
import { BackBtn, Button, Form, Input } from "../../../components";
import { rules, ticketPriorities, ticketStatus } from "../../../constants";
import { axios } from "../../../boot";
import { downloadFile, showFileName, source, toast } from "../../../methods";
import "./index.scss";

export default function Ticket() {
  const params = useParams();
  const inputFileRef = useRef(null);
  const location = useLocation();
  const [data, setData] = useState({});
  const [messages, setMessages] = useState([]);
  const ticketInfo = location.state ?? {};
  const isClosed = ticketInfo.status === 2;
  const getMessages = () => {
    const ticketId = params.id;
    const url = "/support/getTicketChats";
    const body = { ticketId };
    axios.post(url, body).then(({ data }) => {
      setMessages(data);
    });
  };
  const submitMessage = () => {
    const url = "/support/addReplyChat";
    const body = new FormData();
    body.append("ticketId", params.id);
    data.message && body.append("content", data.message);
    data.file && body.append("file", data.file);
    body.append("from", "user");
    axios.post(url, body).then(({ data }) => {
      setMessages((p) => [...p, data.chat]);
      setData({});
      inputFileRef.current.value = "";
      const text = "پیام ثبت شد.";
      toast({ text });
    });
  };
  const showDate = (date) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(date).toLocaleDateString("fa-IR", options);
  };
  const showStatus = (status = 0) => {
    const { color, name } = ticketStatus.find((e) => e.id === status);
    return <span className={`text-${color} fw-bold`}>{name}</span>;
  };
  useEffect(getMessages, []);
  useEffect(() => data.file && submitMessage(), [data.file]);
  return (
    <div className="Ticket wrapper">
      <BackBtn />
      <Row className="justify-content-start">
        <Col xs="12" md="6" lg="4">
          <p>
            <span className="text-dark">موضوع: </span>
            {ticketInfo.title}
          </p>
        </Col>
        <Col xs="12" md="6" lg="4">
          <p>
            <span className="text-dark">سطح اولویت: </span>
            {ticketPriorities.find((e) => e.id === ticketInfo.priority)?.name}
          </p>
        </Col>
        <Col xs="12" md="6" lg="4">
          <p>
            <span className="text-dark">بخش مرتبط: </span>
            {ticketInfo.department?.name}
          </p>
        </Col>
        <Col xs="12" md="6" lg="4">
          <p>
            <span className="text-dark">تاریخ: </span>
            {showDate(ticketInfo.createdAt)}
          </p>
        </Col>
        <Col xs="12" md="6" lg="4">
          <p className="text-truncate">
            <span className="text-dark">وضعیت: </span>
            {showStatus(ticketInfo.status)}
          </p>
        </Col>
      </Row>
      <div className="messages my-3 d-flex flex-column gap-3 w-100">
        <h5 className="w-100">پیام‌ها</h5>
        {messages.map((e) => (
          <div
            key={e._id}
            className={`message position-relative mw-100 w-fit py-2 px-3 rounded ${e.from}`}
          >
            {e.attachment && (
              <div className="attachment d-flex align-items-center gap-2 w-100 bg-dark bg-opacity-10 rounded">
                <div className="position-relative d-flex flex-center rounded overflow-hidden">
                  <img
                    src={source(e.attachment)}
                    alt={e.attachment}
                    className="object-fit-cover w-100 h-100"
                  />
                  <button
                    onClick={() => downloadFile(e.attachment)}
                    className="bi bi-download position-absolute text-white d-flex flex-center bg-dark bg-opacity-50 rounded-circle"
                  />
                </div>
                <h6 dir="ltr" className="text-truncate text-end fs-8 p-2">
                  {showFileName(e.attachment)}
                </h6>
              </div>
            )}
            {e.content && <p className="text-white w-100">{e.content}</p>}
            <span className="text-light fs-7 mt-1 d-block">
              {showDate(e.createdAt)}
            </span>
          </div>
        ))}
      </div>
      {!isClosed && (
        <Form className="row" onSubmit={submitMessage}>
          <Col xs="12" md="10" lg="6">
            <Input
              label="پیام جدید"
              as="textarea"
              value={data.message}
              setValue={(message) => setData((p) => ({ ...p, message }))}
              rules={rules.required}
            />
            <button
              onClick={() => inputFileRef.current.click()}
              type="button"
              className="bi bi-image input-group-text"
            />
            <input
              ref={inputFileRef}
              type="file"
              className="d-none"
              accept=".jpg, .png, .jpeg"
              onChange={({ target }) =>
                setData((p) => ({ ...p, file: target.files[0] }))
              }
            />
          </Col>
          <Col xs="12" className="d-flex flex-center">
            <Button type="submit" className="text-light">
              ارسال
            </Button>
          </Col>
        </Form>
      )}
    </div>
  );
}
