import { Col } from "react-bootstrap";
import { CreditOffline } from "../../../../../components";

export default function IncreaseCreditOfflineSection() {
  return (
    <Col xs="12" lg="6">
      <div className="wrapper">
        <CreditOffline />
      </div>
    </Col>
  );
}
