const landing = [
  {
    label: "صفحه اصلی",
    path: "",
  },
  // {
  //   label: "کارت های اعتباری",
  //   path: "/credit-card",
  // },
  // {
  //   label: "خدمات ما",
  //   children: [],
  // },
  // {
  //   label: "پرداخت بین المللی",
  //   children: [],
  // },
  {
    label: "بلاگ",
    path: "/blog",
  },
  {
    label: "تماس با ما",
    path: "/contact-us",
  },
];
const users = [
  // {
  //   title: "کیف پول",
  //   path: "/wallet",
  // },
  {
    title: "داشبورد",
    path: "/dashboard",
  },
  // {
  //   title: "ویزا مستر کارت",
  //   children: [
  //     {
  //       title: "ویزا مستر کارت فیزیکی",
  //       path: "/visa-master-physical-card",
  //     },
  //   ],
  // },
  {
    title: "خدمات رمز ارز",
    children: [
      {
        title: "خرید ارز",
        path: "/buy-cryptos",
      },
      {
        title: "فروش ارز",
        path: "/sell-cryptos",
      },
    ],
  },
  {
    title: "پرداخت‌های من",
    children: [
      {
        title: "افزایش اعتبار آنلاین",
        path: "/increase-credit-online",
      },
      {
        title: "افزایش اعتبار آفلاین",
        path: "/increase-credit-offline",
      },
      // {
      //   title: "برداشت اعتبار",
      //   path: "/withdraw-credit",
      // },
      // {
      //   title: "تغییرات اعتبار",
      //   path: "/credit-changes",
      // },
    ],
  },
  {
    title: "کارت‌های بانکی",
    path: "/bank-cards",
  },
  {
    title: "تیکت‌ها",
    path: "/tickets",
  },
  {
    title: "پیام‌ها",
    path: "/messages",
  },
  // {
  //   title: "تنظیمات",
  //   path: "/",
  // },
  {
    title: "اطلاعات من",
    path: "/my-information",
  },
  // {
  //   title: "تکمیل اطلاعات",
  //   path: "/authentication",
  // },
];
const navItems = {
  landing,
  users,
};
export default navItems;
