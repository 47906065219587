import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { contactUsCard } from "../../../constants";

export default function ContactUs() {
  return (
    <div className="ContactUs mt-5 ">
      <Container>
        <Row>
          {contactUsCard.map((item, index) => (
            <Col key={index} xs="12" md="6" lg="3">
              <div className="d-flex flex-column flex-center text-center w-100 row-gap-3 py-3 border border-primary rounded shadow-sm">
                <img
                  src={item.image}
                  width="100"
                  height="100"
                  className="object-fit-contain"
                  alt="card-img"
                />
                <h1 className="text-primary h5">{item.title}</h1>
                <p className="text-secondary">{item.value}</p>
              </div>
            </Col>
          ))}
        </Row>
        <Row className="mt-5">
          <Col xs="12" md="6" lg="4">
            <div>
              <h6>دفتر مرکزی:</h6>
              <p>
                تهران - بلوار میرداماد - خیابان شاه نظری - برج
                <br />
                ناهید - طبقه 3 - واحد
              </p>
            </div>
            <div>
              <h6>تلفن:</h6>
              <p className="text-end" dir="ltr">
                +98 21_22275352
              </p>
              <p className="text-end" dir="ltr">
                +98 21_22275351
              </p>
            </div>
          </Col>
          <Col xs="12" md="6" lg="8">
            <iframe
              title="map"
              className="w-100 rounded-5 border shadow-sm bg-white"
              height="300"
              src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=13&ie=UTF8&iwloc=&output=embed"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
            ></iframe>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
