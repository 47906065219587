import apk from "../../src/assets/images/download android.svg";
// import googlePlay from "../../src/assets/images/download google play.svg";
// import appStore from "../../src/assets/images/download app store.svg";
const downloadItems = [
  {
    img: apk,
    href: "",
  },
  // {
  //   img: googlePlay,
  //   href: "",
  // },
  // {
  //   img: appStore,
  //   href: "",
  // },
];
export default downloadItems;
