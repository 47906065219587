const persianReg = /^[\u0600-\u06FF\s]+$/;
const minPrice = [
  (val = "") =>
    Number(val) >= 1000000 || "حداقل مقدار مجاز 1,000,000 تومان است.",
];
const required = [(val = "") => val.length !== 0 || "ورودی الزامی است."];
const phoneNumber = [
  (val = "") => val.length > 0 || "ورودی الزامی است.",
  (val = "") => val.substring(0, 2) === "09" || "شماره باید با 09 شروع شود.",
  (val = "") => val.length === 11 || "شماره موبایل معتبر نیست.",
];
const password = [
  (val = "") => val.length > 0 || "ورودی الزامی است.",
  (val = "") => val.length >= 6 || "طول گذرواژه باید حداقل 6کاراکتر  باشد.",
];
const emailRegex =
  // eslint-disable-next-line
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const email = [(val = "") => emailRegex.test(val) || "ایمیل نامعتبر است"];
const nationalCode = [(val = "") => val.length === 10 || "کد ملی معتبر نیست"];
const onlyPersian = [
  ...required,
  (val = "") => persianReg.test(val) || "ققط کاراکتر فارسی مجاز است.",
];
const cardNumber = [
  ...required,
  (val = "") => val.length === 16 || "شماره کارت نامعتبر است.",
];
const shebaNumber = [
  ...required,
  (val = "") => val.length === 24 || "شماره شبا نامعتبر است.",
];
const accountNumber = [
  ...required,
  (val = "") => val.length <= 20 || "شماره حساب نامعتبر است.",
];
const creditOnline = [
  ...required,
  ...minPrice,
  (val = "") =>
    Number(val) <= 50000000 || "حداکثر مقدار مجاز 50,000,000 تومان است.",
];
const creditOffline = [
  ...required,
  ...minPrice,
  (val = "") =>
    Number(val) <= 200000000 || "حداکثر مقدار مجاز 200,000,000 تومان است.",
];
const buyPrice = [...creditOnline];
const sellPrice = [...required, ...minPrice];

const rules = {
  phoneNumber,
  password,
  required,
  email,
  nationalCode,
  onlyPersian,
  cardNumber,
  shebaNumber,
  accountNumber,
  creditOnline,
  creditOffline,
  buyPrice,
  sellPrice,
};
export default rules;
