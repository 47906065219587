import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { axios } from "../../../boot";
import { toast } from "../../../methods";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";

import steps from "./_steps";
import "./index.scss";
export default function Authentication() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const activeWidth = (100 * activeStep) / (steps.length - 1);
  const stepsComponent = [Step1, Step5, Step3, Step4, Step5];
  const updateAuthStatus = (status) => {
    dispatch({ type: "UPDATE_AUTH_STATUS", status });
  };
  const submit = () => {
    if (activeStep === stepsComponent.length - 1) {
      const url = "/user/Auth";
      const body = new FormData();
      const address = `${data.address} - پلاک ${data.plaque} - واحد ${data.unit}`;
      Object.keys(data).forEach((key) => {
        if (key === "address") {
          body.set(key, address);
        } else {
          body.set(key, data[key]);
        }
      });
      return axios.post(url, body).then(() => {
        const text = "درخواست احراز هویت شما با موفقیت ثبت شد.";
        toast({ text });
        updateAuthStatus(3);
        navigate("/dashboard", { replace: true });
      });
    }
    setActiveStep((p) => p + 1);
  };
  return (
    <div className="Authentication d-flex flex-column row-gap-4">
      <div className="steps d-none position-relative bg-white d-md-flex align-items-start justify-content-between rounded-5 shadow-sm px-5 py-4">
        <hr
          style={{ "--width": `${activeWidth}%` }}
          className="line position-absolute bg-light-gray"
        />
        {steps.map((item, index) => (
          <button
            key={index}
            className={`position-relative step d-flex flex-column flex-center row-gap-2 text-center ${
              activeStep >= index && "active"
            }`}
            onClick={() => index < activeStep && setActiveStep(index)}
          >
            <i
              // onClick={() => setActiveStep(index)}
              className="icon bi bi-check-circle d-flex flex-center rounded-circle fs-4"
            />
            <h6>{item.label}</h6>
          </button>
        ))}
      </div>
      <div className="wrapper">
        {React.createElement(stepsComponent[activeStep], {
          data,
          setData,
          submit,
        })}
      </div>
    </div>
  );
}
