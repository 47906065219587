import { useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { navItems } from "../../constants";
import navbarLogo from "../../assets/logos/logo-text.svg";
import "./index.scss";
export default function Sidebar({ show = false, onHide = () => {} }) {
  const sidebar = useRef();
  const handleShow = () => {
    sidebar.current.classList.toggle("active", show);
  };
  useEffect(handleShow, [show]);
  return (
    <div ref={sidebar} className="Sidebar d-lg-none">
      <div className="items d-flex flex-column row-gap-3">
        <Link to="/">
          <img className="w-100 d-block" src={navbarLogo} alt="navbarLogo" />
        </Link>
        {navItems.landing.map((e) => (
          <NavLink key={e.path} to={e.path} className="text-dark">
            {e.label}
          </NavLink>
        ))}
      </div>
      <button onClick={() => onHide(false)} className="hide-btn" />
    </div>
  );
}
