import React from "react";
import "./index.scss";
import {Button} from "../../../components";
import successBuy from "../../../assets/icons/success-buy.svg";
import Line from "./Line";
export default function SuccessfulPurchase() {
  const userInformation = [
    {
      title: "نام و نام خانوادگی :",
      value: "روژینا رضایی",
    },
    {
      title: "مقدار ارز دریافتی :",
      value: "0,02BTC",
    },
    {
      title: "زمان پرداخت :",
      value: "1400/12/20",
    },
  ];
  return (
    <div className="SuccessfulPurchase bg-white container rounded shadow-sm">
      <div>
        <Button variant="violet" outline className="border-0 d-flex me-auto">
          بازگشت
          <i className="bi bi-arrow-left"></i>
        </Button>
      </div>
      <div className="py-2">
        <div className=" d-flex flex-column flex-center bg-primary bg-opacity-10 border-solid border-primary rounded-10">
          <div>
            <div className="py-4 d-flex flex-column row-gap-4">
              <img src={successBuy} alt="success-buy" />
              <h5 className="white-space-pre-wrap text-center text-success fw-bold">
                خرید ارز دیجیتال{"\n"}
                باموفقیت انجام شد
              </h5>
              <div className="d-flex py-4 px-4 bg-success bg-opacity-10 border-dashed border-success rounded col-gap-3">
                <h6 className="text-success">مبلغ پرداخت شده:</h6>
                <p className="text-success">300,000 تومان</p>
              </div>
            </div>
          </div>
          <div className="bg-white rounded col-12 col-md-7">
            {userInformation.map((item, index) => (
              <div className="d-flex px-4 py-3 " key={index}>
                <h6 className="text-primary fw-bold">{item.title}</h6>
                <h6 className="text-dark fw-bold me-auto">{item.value}</h6>
              </div>
            ))}
          </div>
          <Line />
          <p className="col-12 col-md-7 text-center mb-5 h5">
            در صورت داشتن هرگونه سوال و مشکل ,با شماره پشتیبانی 75046300 تماس
            بگیرید.
          </p>
        </div>
      </div>
    </div>
  );
}
