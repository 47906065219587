import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "../../../components";
import { axios, moment } from "../../../boot";
import { ticketStatus } from "../../../constants";

export default function Tickets() {
  const [tickets, setTickets] = useState([]);
  const getTickets = () => {
    const url = "/support/getUserTickets";
    axios.post(url).then(({ data }) => {
      setTickets(data);
    });
  };
  const showStatus = (status = 0) => {
    const { color, name } = ticketStatus.find((e) => e.id === status);
    return (
      <span
        className={`bg-${color} text-${color} bg-opacity-10 fw-bold px-2 py-1 rounded`}
      >
        {name}
      </span>
    );
  };
  useEffect(getTickets, []);
  return (
    <div className="Tickets">
      <div className="bg-white px-3 py-3 shadow-sm rounded-5">
        <div className="d-flex justify-content-between">
          <h5 className="text-primary">لیست تیکت‌ها</h5>
          <Link to="/new-ticket" className="btn btn-violet rounded-5 px-4 py-2">
            ساخت تیکت جدید
          </Link>
        </div>
        <Table className="text-center bg-light-blue rounded-5 mt-3">
          <thead className="text-secondary">
            <tr>
              <td>#</td>
              <td>موضوع تیکت</td>
              <td>سطح اولویت</td>
              <td>بخش مرتبط</td>
              <td>تاریخ ثبت تیکت</td>
              <td>وضعیت</td>
            </tr>
          </thead>
          <tbody className="bg-white border-white">
            {tickets.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.title}</td>
                <td>{item.priority}</td>
                <td>{item.department?.name ?? "-"}</td>
                <td>{moment.miladiToShamsi({ date: item.createdAt })}</td>
                <td>{showStatus(item.status)}</td>
                <td>
                  <Link
                    to={item._id}
                    state={item}
                    className="btn btn-primary text-white rounded-5"
                  >
                    مشاهده پیام‌ها
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
