const cardPrefixes = [
  {
    name: "بانک ملی ایران",
    id: "603799",
  },
  {
    name: "بانک مسکن",
    id: "628023",
  },
  {
    name: "بانک سپه",
    id: "589210",
  },
  {
    name: "بانک کشاورزی",
    id: "639217",
  },
  {
    name: "بانک کشاورزی",
    id: "603770",
  },
  {
    name: "بانک توسعه تعاون",
    id: "502908",
  },
  {
    name: "بانک توسعه صادرات ایران",
    id: "207177",
  },
  {
    name: "بانک توسعه صادرات ایران",
    id: "627648",
  },
  {
    name: "بانک صنعت و معدن",
    id: "627961",
  },
  {
    name: "پست بانک ایران",
    id: "627760",
  },
  {
    name: "بانک ملت",
    id: "610433",
  },
  {
    name: "بانک ملت",
    id: "991975",
  },
  {
    name: "بانک تجارت",
    id: "585983",
  },
  {
    name: "بانک صادرات ایران",
    id: "603769",
  },
  {
    name: "بانک رفاه کارگران",
    id: "589463",
  },
  {
    name: "بانک سامان",
    id: "621986",
  },
  {
    name: "بانک پارسیان",
    id: "639194",
  },
  {
    name: "بانک پارسیان",
    id: "622106",
  },
  {
    name: "بانک پارسیان",
    id: "627884",
  },
  {
    name: "بانک پاسارگاد",
    id: "639347",
  },
  {
    name: "بانک پاسارگاد",
    id: "502229",
  },
  {
    name: "بانک انصار",
    id: "627381",
  },
  {
    name: "بانک سینا",
    id: "639346",
  },
  {
    name: "بانک شهر",
    id: "502806",
  },
  {
    name: "بانک اقتصاد نوین",
    id: "627412",
  },
  {
    name: "بانک سرمایه",
    id: "639607",
  },
  {
    name: "بانک آینده",
    id: "636214",
  },
  {
    name: "بانک قوامین",
    id: "639599",
  },
  {
    name: "بانک ایران زمین",
    id: "505785",
  },
  {
    name: "بانک دی",
    id: "502938",
  },
  {
    name: "بانک سینا",
    id: "639346",
  },
  {
    name: "بانک کارآفرین",
    id: "627488",
  },
  {
    name: "بانک کارآفرین",
    id: "502910",
  },
  {
    name: "بانک گردشگری",
    id: "505416",
  },
  {
    name: "بانک حکمت ایرانیان",
    id: "636949",
  },
  {
    name: "بانک  مهر ایران",
    id: "606373",
  },
  {
    name: "موسسه اعتباری کوثر",
    id: "505801",
  },
  {
    name: "موسسه اعتباری توسعه",
    id: "628157",
  },
  {
    name: "بانک مهر اقتصاد",
    id: "639370",
  },
];
export default cardPrefixes;
