import { useEffect, useRef } from "react";
import "./index.scss";
export default function Modal({
  show = true,
  onHide = () => {},
  variant = "primary",
  title = "",
  children = null,
  closeBtn = false,
}) {
  const modal = useRef();
  const handleShow = () => {
    modal.current.classList.toggle("show", show);
  };
  useEffect(handleShow, [show]);
  return (
    <div
      ref={modal}
      className="Modal position-fixed d-flex flex-center overflow-hidden p-2 transition"
    >
      <button
        type="button"
        className="hide-btn"
        onClick={() => onHide(false)}
      />
      <div className="dialog w-100 modal-dialog border border-light-gray bg-white rounded shadow-sm position-relative transition overflow-auto">
        <div className="header w-100 d-flex flex-center text-center">
          <div
            className={`title d-flex align-items-center justify-content-${
              closeBtn ? "between" : "center"
            } w-100 pt-3 px-3 fs-6 text-${variant}`}
          >
            <span>{title}</span>
            {closeBtn && (
              <i
                className={`bi bi-x-lg cursor-pointer text-${variant}`}
                onClick={() => onHide(false)}
              ></i>
            )}
          </div>
        </div>
        <div className="body p-3">{children}</div>
      </div>
    </div>
  );
}
