import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { moment } from "../../../../../boot";

export default function MassagesSection({ message = {} }) {
  return (
    <Col xs="12" md="6">
      <div className="wrapper h-100 d-flex flex-column">
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="text-violet m-0">پیام‌ها</h5>
          <Link to="/messages" className="d-flex flex-center text-blue">
            مشاهده همه
            <i className="bi bi-arrow-left" />
          </Link>
        </div>
        {/* <div className="d-flex align-items-center justify-content-between"></div> */}
        <h6 className="text-primary mt-3">{message.title}</h6>
        <p className="line-clamp-3 mb-1">{message.content}</p>
        <span className="d-block text-end mt-auto">
          {moment.miladiToShamsi({ date: message.createdAt })}
        </span>
      </div>
    </Col>
  );
}
