import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { axios } from "../../../boot";
import { BlogCard, Pagination } from "../../../components";
export default function Blog() {
  const [blogList, setBlogList] = useState({});
  const [activePage, setActivePage] = useState(1);
  const getBlogList = () => {
    const url = "/blog/getPosts";
    const params = { limit: 9, currentPage: activePage };
    axios.get(url, { params }).then(({ data }) => {
      setBlogList(data);
    });
  };
  useEffect(getBlogList, [activePage]);
  return (
    <Container className="mt-5">
      <Row>
        <div className="text-center">
          <h1 className="h3">بلاگ</h1>
        </div>
        {blogList.posts?.map((e) => (
          <Col key={e._id} xs="12" md="6" lg="4">
            <BlogCard {...e} />
          </Col>
        ))}
      </Row>
      <Pagination
        activePage={activePage}
        setActivePage={setActivePage}
        totalPages={blogList.totalPages}
      />
    </Container>
  );
}
