const ticketPriorities = [
  {
    id: "اضطراری",
  },
  {
    id: "عادی",
  },
  {
    id: "پایین",
  },
].map((e) => ({ ...e, name: e.id }));
export default ticketPriorities;
