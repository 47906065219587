import React, { useState } from "react";
import { rules } from "../../../constants";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Input } from "../../../components";
import { axios } from "../../../boot";
import { toast } from "../../../methods";
export default function ForgetPassword() {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [data, setData] = useState({});
  const formControls1 = [
    {
      label: "شماره تلفن همراه",
      state: "phone",
      type: "number",
      rules: rules.phoneNumber,
    },
  ];
  const formControls2 = [
    {
      label: "کد پیامک شده",
      state: "code",
      type: "number",
      rules: rules.required,
    },
    {
      label: "گذرواژه جدید",
      state: "password",
      type: "password",
      rules: rules.password,
    },
  ];
  const formControls = [formControls1, formControls2];
  const verify = () => {
    const url = "/verifyRegister";
    const body = {
      phone: data.phone,
    };
    axios.post(url, body).then(() => {
      setStep(1);
    });
  };
  const resetPassword = () => {
    const url = "/user/resetPassword";
    const body = data;
    axios.post(url, body).then(() => {
      navigate("/login");
      const text = "عملیات با موفقیت انجام شد";
      toast({ text });
    });
  };
  return (
    <Form
      onSubmit={step === 0 ? verify : resetPassword}
      className="RegisterForm bg-white d-flex flex-center rounded row-gap-3 w-100 p-4 flex-column"
    >
      <h4 className="text-violet fw-bold text-center">فراموشی رمز عبور</h4>
      {formControls[step].map((item, index) => (
        <Input
          key={index}
          label={item.label}
          type={item.type}
          rules={item.rules}
          value={data[item.state]}
          setValue={(val) => {
            setData((p) => ({ ...p, [item.state]: val }));
          }}
        />
      ))}
      <Button type="submit" className="text-white w-100">
        {step === 0 ? "ارسال پیامک" : "ثبت"}
      </Button>

      <p>آیا حساب کاربری دارید؟</p>
      <Link to="/login" replace>
        وارد شوید
      </Link>
    </Form>
  );
}
