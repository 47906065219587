import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button, Modal } from "../../../../../components";
import { authStatus } from "../../../../../constants";
import "./index.scss";
export default function AuthBox({
  totalbuy = 0,
  totalcards = 0,
  totalsell = 0,
  totaltickets = 0,
}) {
  const cards = [
    {
      icon: "cart3",
      title: "خرید از پیشرو",
      to: { pathname: "/buy-cryptos", search: "tab=orders" },
      value: totalbuy,
    },
    {
      icon: "basket3-fill",
      title: "فروش به پیشرو",
      to: { pathname: "/sell-cryptos", search: "tab=orders" },
      value: totalsell,
    },
    {
      icon: "chat-square-text",
      title: "تعداد تیکت‌ها",
      to: "/tickets",
      value: totaltickets,
    },
    {
      icon: "credit-card",
      title: "تعداد کارت ثبت شده",
      to: "/bank-cards",
      value: totalcards,
    },
  ];
  const navigate = useNavigate();
  const [description, setDescription] = useState(false);
  const { authentication, adminDescription } = useSelector((s) => s.profile);
  const auth = authStatus.find((e) => e.id === authentication) ?? {};
  const isAuth = auth.id === 1;
  return (
    <Row className="align-items-stretch">
      {!isAuth && (
        <Col xs="12" lg="8" xl="4">
          <div className="d-flex gap-3 bg-white border border-violet rounded shadow-sm row-gap-3 p-2">
            <div
              style={{ flex: "2" }}
              className="d-flex flex-column justify-content-between"
            >
              <h6 className="text-violet">احراز هویت</h6>
              {auth.id === 2 && (
                <button
                  onClick={() => setDescription(true)}
                  className="text-decoration-underline text-info w-fit"
                >
                  مشاهده دلیل رد احراز هویت
                </button>
              )}
              <Button
                disabled={auth.id === 3}
                variant="primary"
                className="text-white w-100"
                onClick={() => navigate("/authentication")}
              >
                تکمیل اطلاعات
              </Button>
            </div>
            <div
              style={{ flex: "1" }}
              className={`d-flex flex-column align-items-center justify-content-between py-1 px-2 fw-bold rounded text-${auth.color} bg-${auth.color} bg-opacity-10`}
            >
              <i className="bi bi-info-lg fs-2 d-flex flex-center" />
              <span>وضعیت</span>
              <span className="text-secondary text-center fs-7">
                {auth.name}
              </span>
            </div>
          </div>
          <Modal
            show={description}
            onHide={setDescription}
            title="احراز هویت شما رد شد."
            variant="danger"
          >
            <p className="text-center">{adminDescription}</p>
            <Button
              onClick={() => setDescription(false)}
              className="text-white d-block mx-auto mt-4"
            >
              متوجه شدم
            </Button>
          </Modal>
        </Col>
      )}
      {cards.map((item, index) => (
        <Col key={index} xs="12" md="6" lg="4" xl={isAuth ? "3" : "2"}>
          <Link
            to={item.to}
            className="auth-box d-flex flex-column flex-center bg-white border border-primary rounded row-gap-3 py-3"
          >
            <i
              style={{ width: "55px", height: "55px" }}
              className={`d-flex flex-center fs-2 bg-primary bg-opacity-10 text-primary rounded-circle bi bi-${item.icon}`}
            />
            <h6 className="text-primary fs-7">
              {item.title}: <span className="text-secondary">{item.value}</span>
            </h6>
          </Link>
        </Col>
      ))}
    </Row>
  );
}
