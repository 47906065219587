import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function NeedAuth() {
  const authentication = useSelector((s) => s.profile?.authentication ?? 0);
  return (
    authentication !== 1 && (
      <Alert variant="warning" className="w-100">
        <p className="text-warning">
          <i className="bi bi-exclamation-circle-fill fs-5 ms-1"></i>
          کاربر عزیز جهت انجام فرایند <span className="fw-bold">
            خرید
          </span> یا <span className="fw-bold">فروش</span> نیاز به{" "}
          <Link
            to="/authentication"
            className="text-info text-decoration-underline"
          >
            احراز هویت
          </Link>{" "}
          دارید.
        </p>
      </Alert>
    )
  );
}
