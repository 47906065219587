import { useEffect, useState } from "react";
import { Table } from "../../../components";
import { axios } from "../../../boot";
import { Link } from "react-router-dom";
import { toast } from "../../../methods";

export default function FavCryptos({ isBuy, transactionLabel }) {
  const [favCryptos, setFavCryptos] = useState([]);
  const getFavCryptos = () => {
    const url = "/user/getUserFav";
    axios.post(url).then(({ data }) => {
      setFavCryptos(data.favList);
    });
  };
  const removeFromFav = (coinsymbol = "") => {
    const url = "/user/deleteFavCoin";
    const body = { coinsymbol };
    axios.post(url, body).then(() => {
      const text = "ارز از علاقمندی ها حذف شد";
      toast({ text });
      setFavCryptos((p) => {
        const favCryptos = [...p];
        const index = favCryptos.findIndex((e) => e.symbol === coinsymbol);
        favCryptos.splice(index, 1);
        return favCryptos;
      });
    });
  };
  const showChangeValue = (val = 0) => {
    if (val < 0) return <span className="text-danger">{val}</span>;
    if (val > 0) return <span className="text-success">+{val}</span>;
    return <span className="text-dark">{0}</span>;
  };
  useEffect(getFavCryptos, []);
  return (
    <Table className="text-center bg-light-blue rounded">
      <thead className="text-secondary">
        <tr>
          <td className="text-start">نام ارز</td>
          <td>قیمت(دلار)</td>
          <td>قیمت(تومان)</td>
          <td>حجم بازار</td>
          <td></td>
        </tr>
      </thead>
      <tbody className="text-dark-blue fw-bold bg-white border-white">
        {favCryptos.map((item) => (
          <tr key={item._id}>
            <td className="text-start">
              <div className="w-fit d-flex flex-center col-gap-2">
                <button
                  className="bi bi-star-fill text-warning"
                  onClick={() => removeFromFav(item.symbol)}
                />
                <img
                  width="35"
                  height="35"
                  className="object-fit-contain"
                  src={item.image}
                  alt={item.name}
                />
                <p className="white-space-pre-wrap">
                  <span className="text-dark-blue">{item.persianName}</span>
                  {"\n"}
                  <span className="text-secondary fs-7">
                    {item.name}~
                    <span className="text-uppercase">{item.symbol}</span>
                  </span>
                </p>
              </div>
            </td>
            <td dir="ltr" className="white-space-pre-wrap">
              $ {item.priceUsdt}
              {"\n"}
              {showChangeValue(item.change24h.toFixed(2))}
            </td>
            <td>
              {item[isBuy ? "buyPriceToman" : "sellPriceToman"].toFixed(5)}
            </td>
            <td>${Number(item.marketCap).toLocaleString()}</td>
            <td>
              <Link
                to={item._id}
                state={item}
                className="btn btn-primary py-1 text-white px-4"
              >
                {transactionLabel}
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
