import BAccordion from "react-bootstrap/Accordion";
import "./index.scss";
export default function Accordion({
  title = "",
  defaultSetActive = false,
  children = "",
  variant = "primary",
  className = "",
}) {
  return (
    <BAccordion
      className={className}
      defaultActiveKey={defaultSetActive ? "0" : null}
    >
      <BAccordion.Item eventKey="0">
        {/* <BAccordion.Header className="bg-info">{title}</BAccordion.Header> */}
        <BAccordion.Button
          className={`btn d-flex align-items-center col-gap-2 text-start text-${variant} bg-white`}
        >
          <i
            className={`bi bi-chevron-down transition icon d-flex flex-center fs-5 rounded-circle bg-${variant} bg-opacity-25`}
          />
          {title}
        </BAccordion.Button>

        <BAccordion.Body>{children}</BAccordion.Body>
      </BAccordion.Item>
    </BAccordion>
  );
}
