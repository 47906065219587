import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { rules } from "../../../constants";
import { Button, Form, Input } from "../../../components";
import { axios } from "../../../boot";
import { useSetToken } from "../../../hooks";
export default function LoginForm() {
  const navigate = useNavigate();
  const setToken = useSetToken();
  const [data, setData] = useState({});
  const formControls = [
    {
      label: "شماره تلفن همراه",
      state: "phone",
      type: "number",
      rules: rules.phoneNumber,
    },
    {
      label: "گذرواژه",
      state: "password",
      type: "password",
      rules: rules.password,
    },
  ];
  const login = () => {
    const url = "/user/login";
    const body = data;
    axios.post(url, body).then(({ data }) => {
      const token = data.token;
      setToken(token);
      navigate("/dashboard");
    });
  };
  return (
    <Form
      onSubmit={login}
      className="LoginForm bg-white d-flex flex-center rounded row-gap-3 w-100 p-4 flex-column"
    >
      <h4 className="text-violet fw-bold text-center">ورود به حساب</h4>
      {formControls.map((item, index) => (
        <Input
          key={index}
          label={item.label}
          type={item.type}
          rules={item.rules}
          value={data[item.state]}
          setValue={(val) => {
            setData((p) => ({ ...p, [item.state]: val }));
          }}
        />
      ))}
      <Link to="/forget-password">فراموشی رمز ورود</Link>
      <Button type="submit" className="text-white w-100">
        ورود
      </Button>
      <p>آیا حساب کاربری ندارید؟</p>
      <Link to="/register" replace>
        ثبت نام
      </Link>
    </Form>
  );
}
