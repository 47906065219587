const authStatus = [
  {
    name: "احراز هویت کنید",
    id: 0,
    color: "info",
  },
  {
    name: "تایید شده",
    id: 1,
    color: "success",
  },
  {
    name: "رد شده",
    id: 2,
    color: "danger",
  },
  {
    name: "در انتظار تایید",
    id: 3,
    color: "warning",
  },
];
export default authStatus;
