import React, { useEffect, useRef, useState } from "react";
import { Col } from "react-bootstrap";
import { BackBtn, Button, Form, Input, Select } from "../../../components";
import { rules, ticketPriorities } from "../../../constants";
import { axios } from "../../../boot";
import "./index.scss";
import { toast } from "../../../methods";
import { useNavigate } from "react-router";

export default function NewTicket() {
  const navigate = useNavigate();
  const inputFile = useRef();
  const [data, setData] = useState({
    from: "user",
  });
  const [departments, setDepartments] = useState([]);
  const formControls = [
    {
      tag: Select,
      label: "اولویت",
      state: "priority",
      items: ticketPriorities,
      rules: rules.required,
    },
    {
      tag: Select,
      label: "دپارتمان",
      state: "department",
      items: departments,
      rules: rules.required,
    },
    {
      label: "عنوان",
      state: "title",
      rules: rules.required,
    },
    {
      label: "محتوا",
      state: "content",
      rules: rules.required,
      as: "textarea",
    },
  ];
  const getDepartments = () => {
    const url = "/support/getDepartment";
    axios.post(url).then(({ data }) => {
      setDepartments(data.map((e) => ({ name: e.name, id: e._id })));
    });
  };
  const uploadFile = (e) => {
    const file = e.target.files[0];
    setData((p) => ({ ...p, file }));
  };
  const submitNewTicket = () => {
    const url = "/support/addNewTicket";
    const body = new FormData();
    Object.keys(data).forEach((key) => {
      body.append(key, data[key]);
    });
    body.append("status", 0);
    axios.post(url, body).then(() => {
      const text = "تیکت با موفقیت ثبت شد";
      toast({ text });
      navigate(-1, { replace: true });
    });
  };
  useEffect(getDepartments, []);
  return (
    <Form
      onSubmit={submitNewTicket}
      className="NewTicket row bg-white shadow-sm rounded align-items-start py-3"
    >
      <div className="d-flex flex-center col-12">
        <h6 className="text-primary fw-bold">ثبت تیکت جدید</h6>
        <BackBtn />
      </div>
      {formControls.map((item, index) => (
        <Col key={index} xs="12" lg={index === 2 ? "12" : "6"}>
          {React.createElement(item.tag ?? Input, {
            ...item,
            value: data[item.state],
            setValue: (val) => setData((p) => ({ ...p, [item.state]: val })),
          })}
        </Col>
      ))}
      <Col xs="12" lg="6">
        <div className="file-section">
          <span className="me-1 mb-1 d-block">پیوست‌ها</span>
          <button
            type="button"
            onClick={() => inputFile.current.click()}
            className="bg-transparent w-100 d-flex flex-column flex-center row-gap-2 text-center text-secondary"
          >
            <i className="display-6 bi bi-file-earmark-arrow-up" />
            <h6>برای آپلود عکس اینجا کلیک کنید</h6>
            <p>{data.file ? data.file.name : "نام فایل پیوستی"}</p>
            <input
              accept=".png, .jpg"
              type="file"
              ref={inputFile}
              className="d-none"
              onInput={uploadFile}
            />
          </button>
        </div>
      </Col>
      <div className="col-12 d-flex flex-center">
        <Button type="submit" className="text-white px-5 py-2">
          ثبت تیکت جدید
        </Button>
      </div>
    </Form>
  );
}
