import { Link } from "react-router-dom";
import "./index.scss";
export default function BlogCard(props) {
  const { image, title, content, _id, createdAt } = props;
  const date = new Date(createdAt).toLocaleDateString("fa-IR", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  return (
    <div className="BlogCard d-flex position-relative flex-center flex-column bg-white rounded shadow-sm white-space-pre-wrap">
      <span className="date position-absolute shadow px-3 py-1 bg-primary text-dark fs-4 fw-bold">
        {date}
      </span>
      <div className="img-box overflow-hidden rounded">
        <img className="w-100 h-100 object-fit-cover" src={image} alt={title} />
      </div>
      <div className=" w-100 p-3 d-flex flex-column flex center">
        <h1 className="h5">{title}</h1>
        <p className="line-clamp-3">{content}</p>
        <Link
          to={`/blog/${_id}`}
          state={props}
          className="ms-auto text-violet mt-2"
        >
          جزئیات بیشتر<i className="bi bi-arrow-left"></i>
        </Link>
      </div>
    </div>
  );
}
