import React, { useState } from "react";
import { rules } from "../../../constants";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Input, Select } from "../../../components";
import { axios } from "../../../boot";
import { useSetToken } from "../../../hooks";
import { toast } from "../../../methods";
export default function Register() {
  const navigate = useNavigate();
  const setToken = useSetToken();
  const [step, setStep] = useState(0);
  const [data, setData] = useState({
    moaref: "",
  });
  const formControls1 = [
    {
      label: "شماره تلفن همراه",
      state: "phone",
      type: "number",
      rules: rules.phoneNumber,
    },
  ];
  const formControls2 = [
    {
      label: "کد پیامک شده",
      state: "code",
      type: "number",
      rules: rules.required,
    },
    {
      label: "نام و نام خانوادگی",
      state: "name",
      type: "text",
      rules: rules.onlyPersian,
    },
    {
      label: "تاریخ تولد",
      state: "birthday",
      type: "date",
      rules: rules.required,
    },
    {
      tag: Select,
      label: "جنسیت",
      state: "gender",
      rules: rules.required,
      items: [
        { name: "مرد", id: "male" },
        { name: "زن", id: "female" },
      ],
    },
    {
      label: "ایمیل",
      state: "email",
      type: "email",
      rules: rules.email,
    },
    {
      label: "گذرواژه",
      state: "password",
      type: "password",
      rules: rules.password,
    },
    {
      label: "کد معرف (اختیاری)",
      state: "moaref",
      rules: [() => true],
    },
  ];
  const formControls = [formControls1, formControls2];
  const verify = () => {
    const url = "/user/verifyRegister";
    const body = {
      phone: data.phone,
    };
    axios.post(url, body).then(() => {
      setStep(1);
    });
  };
  const register = () => {
    const url = "/user/register";
    const body = data;
    axios.post(url, body).then(({ data }) => {
      const token = data.token;
      const text = "ثبت نام شما با موفقیت انجام شد.";
      setToken(token);
      toast({ text });
      navigate("/dashboard");
    });
  };
  return (
    <Form
      onSubmit={step === 0 ? verify : register}
      className="RegisterForm bg-white d-flex flex-center rounded row-gap-3 w-100 p-4 flex-column"
    >
      <h4 className="text-violet fw-bold text-center">ایجاد حساب کاربری</h4>
      {formControls[step].map((e) =>
        React.createElement(e.tag ?? Input, {
          ...e,
          key: e.state,
          value: data[e.state],
          setValue: (val) => setData((p) => ({ ...p, [e.state]: val })),
        })
      )}
      <Button type="submit" className="text-white w-100">
        {step === 0 ? "ارسال پیامک" : "عضویت"}
      </Button>
      <Link to="/login" replace>
        وارد شوید
      </Link>
    </Form>
  );
}
