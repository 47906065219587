import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import AuthBox from "./Components/AuthBox";
import DiscountCode from "./Components/DiscountCode";
import FavoritesSection from "./Components/FavoritesSection";
import IncreaseCreditOfflineSection from "./Components/IncreaseCreditOfflineSection";
import IncreaseCreditOnlineSection from "./Components/IncreaseCreditOnlineSection";
import LastBuySection from "./Components/LastBuySection";
import LastSellSection from "./Components/LastSellSection";
import MassagesSection from "./Components/MassagesSection";
import TicketSection from "./Components/TicketsSection";
import { axios } from "../../../boot";
import "./index.scss";
export default function Dashboard() {
  const [data, setData] = useState({});
  const getDashboardData = () => {
    const url = "/user/getUserDashboard";
    axios.post(url, {}).then((res) => {
      const data = { ...res.data };
      data.cards = data.cards.map((e) => ({
        id: e._id,
        name: `${e.bankName} - (${e.cardNumber})`,
      }));
      setData(data);
    });
  };
  useEffect(getDashboardData, []);
  return (
    <div className="Dashboard">
      <AuthBox {...data} />
      <Row className="mt-3 align-items-stretch">
        <IncreaseCreditOnlineSection cards={data.cards} />
        <IncreaseCreditOfflineSection />
        <LastBuySection orders={data.buyorders} />
        <LastSellSection orders={data.sellorders} />
        <TicketSection tickets={data.tickets} />
        <MassagesSection message={data.message?.at(0)} />
        <DiscountCode discount={data.discount} />
        <FavoritesSection fav={data.fav} />
      </Row>
    </div>
  );
}
