import sell from "../assets/icons/support1.svg";
import support from "../assets/icons/support2.svg";
import cash from "../assets/icons/support3.svg";
import humanSrc from "../assets/icons/support4.svg";

const contactUsCard = [
  {
    image: sell,
    title: "فروش",
    value: "021-88673284",
  },
  {
    image: support,
    title: "پشتیبانی",
    value: "021-88673284",
  },
  {
    image: cash,
    title: "مالی",
    value: "021-88673284",
  },
  {
    image: humanSrc,
    title: "منابع انسانی",
    value: "021-88673284",
  },
];
export default contactUsCard;
