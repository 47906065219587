import React from "react";
import { ButtonGroup, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Table } from "../../../../../components";

export default function FavoritesSection({ fav = [] }) {
  const showChangeValue = (val = 0) => {
    if (val < 0)
      return (
        <span dir="ltr" className="text-danger">
          {val}
        </span>
      );
    if (val > 0)
      return (
        <span dir="ltr" className="text-success">
          +{val}
        </span>
      );
    return <span className="text-dark">{0}</span>;
  };
  return (
    <Col xs="12" md="6">
      <div className="wrapper">
        <h5 className="text-violet">علاقه‌مندی‌ها</h5>
        <Table>
          <thead className="text-secondary">
            <tr>
              <td>نام ارز</td>
              <td>قیمت(ت)</td>
              <td>24 ساعت</td>
              <td>7 روز</td>
              <td />
            </tr>
          </thead>
          <tbody>
            {fav.map((e) => (
              <tr key={e._id}>
                <td>
                  <div className="d-flex align-items-center gap-1">
                    <img
                      width="35"
                      height="35"
                      className="object-fit-contain"
                      src={e.image}
                      alt={e.name}
                    />
                    <h6 className="fs-7 mb-0">
                      {e.persianName}
                      <br />
                      <span className="fs-8 text-secondary">
                        {e.name} - {e.symbol}
                      </span>
                    </h6>
                  </div>
                </td>
                <td>{e.sellPriceToman.toLocaleString()}</td>
                <td>{showChangeValue(e.change24h.toFixed(2))}</td>
                <td>{showChangeValue(e.change7d.toFixed(2))}</td>
                <td>
                  <ButtonGroup>
                    <Link
                      to={`/sell-cryptos/${e._id}`}
                      className="btn btn-primary btn-sm text-white"
                      state={e}
                    >
                      فروش
                    </Link>
                    <Link
                      to={`/buy-cryptos/${e._id}`}
                      className="btn btn-primary btn-sm text-white"
                      state={e}
                    >
                      خرید
                    </Link>
                  </ButtonGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Col>
  );
}
