import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Select } from "../../components";
import { axios } from "../../boot";
import { rules } from "../../constants";
import { copyText, toast } from "../../methods";
import { Alert } from "react-bootstrap";

export default function CreditOffline({ afterSubmit = () => {} }) {
  const inputFileRef = useRef(null);
  const [step, setStep] = useState(0);
  const [data, setData] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [activeAccountId, setActiveAccountId] = useState("");
  const activeAccount = accounts.find((e) => e.id === activeAccountId) ?? {};
  const getAccounts = () => {
    if (step === 0) return;
    const url = "/account/getAccount";
    axios.post(url).then(({ data }) => {
      const accounts = data.map((e) => ({
        ...e,
        name: `${e.name} - ${e.bankName}`,
        id: e._id,
      }));
      setAccounts(accounts);
      setActiveAccountId(accounts[0].id ?? "");
    });
  };
  const infoItems = [
    {
      label: "شماره کارت",
      state: "cardNumber",
      hasCopyBtn: true,
    },
    {
      label: "شماره حساب",
      state: "hesabNumber",
      hasCopyBtn: true,
    },
    {
      label: "شماره شبا",
      state: "shebaNumber",
      hasCopyBtn: true,
    },
    {
      label: "به نام",
      state: "name",
    },
  ];
  const submitStep = () => setStep(1);
  const submitForm = () => {
    const url = "/pay/addofflinePay";
    const body = new FormData();
    Object.keys(data).forEach((key) => {
      body.append(key, data[key]);
    });
    axios.post(url, body).then(() => {
      const text = "پرداخت شما ثبت شد";
      toast({ text });
      setData({});
      setStep(0);
      afterSubmit();
    });
  };
  const copyValue = (state = "") => {
    const value = activeAccount[state];
    copyText(value)
      .then(() => {
        const text = "کپی شد.";
        toast({ text });
      })
      .catch(() => {
        const text = "خطا در کپی مقدار لطفا مجددا تلاش کنید.";
        toast({ text, type: "error" });
      });
  };
  useEffect(getAccounts, [step]);
  return (
    <Form
      onSubmit={step === 0 ? submitStep : submitForm}
      className="d-flex flex-column row-gap-3"
    >
      <h5 className="text-violet">افزایش اعتبار آفلاین</h5>
      <Input
        value={data.amount}
        setValue={(amount) => setData((p) => ({ ...p, amount }))}
        placeholder="مبلغ به تومان وارد شود"
        type="price"
        rules={rules.creditOffline}
        append={
          <Button type="submit" size="md" variant="violet">
            افزایش اعتبار آفلاین
          </Button>
        }
      />
      {step === 1 && (
        <React.Fragment>
          <Select
            label="انتخاب بانک مقصد"
            rules={rules.required}
            items={accounts}
            value={activeAccountId}
            setValue={setActiveAccountId}
          />
          <Alert variant="success">
            {infoItems.map((e) => (
              <div
                key={e.state}
                className="w-100 d-flex align-items-center justify-content-between"
              >
                <span>{e.label}:</span>
                <span dir="ltr" className="text-secondary">
                  {e.hasCopyBtn && (
                    <button
                      onClick={() => copyValue(e.state)}
                      type="button"
                      className="d-inline-flex flex-center text-secondary bi bi-files me-1"
                    />
                  )}
                  {activeAccount[e.state]}
                </span>
              </div>
            ))}
          </Alert>
          <Input
            label="شماره پیگیری"
            placeholder="شماره پیگیری را وارد کنید"
            type="number"
            value={data.refNumber}
            setValue={(refNumber) => setData((p) => ({ ...p, refNumber }))}
            rules={rules.required}
          />
          <Input
            label="تصویر رسید بانکی"
            value={data.image?.name}
            readOnly
            rules={rules.required}
            append={
              <Button
                onClick={() => inputFileRef.current.click()}
                variant="light-gray"
              >
                انتخاب فایل
              </Button>
            }
          />
          <input
            ref={inputFileRef}
            type="file"
            className="d-none"
            onChange={({ target }) =>
              setData((p) => ({ ...p, image: target.files[0] }))
            }
          />
          <Button type="submit" className="w-100 text-white mt-auto">
            ارسال
          </Button>
        </React.Fragment>
      )}
    </Form>
  );
}
