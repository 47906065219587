import React from "react";
import "./index.scss";
import { Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router";
import loginBackGround from "../../../assets/images/login/bg login.png";
import loginBackgroundMoney from "../../../assets/images/login/people-exchanging-money.png";
import goldComma from "../../../assets/icons/two-cama-gold.svg";
export default function Login() {
  return (
    <div className="w-100 position-relative py-5">
      <img className="bg-img " src={loginBackGround} alt="loginBackGround" />
      <img
        className="bg-img opacity-4"
        src={loginBackgroundMoney}
        alt="loginbackgroundmoney"
      />
      <Container className="position-relative">
        <Row className="justify-content-between w-100 ">
          <Col xs="12" md="6" lg="4">
            <Outlet />
            {/* <LoginForm /> */}
            {/* <RegisterForm /> */}
          </Col>
          <Col dir="ltr" xs="12" md="6" lg="5">
            <div className="text-start position-relative w-fit">
              <img src={goldComma} alt="goldComma" />
              <p className="text-white white-space-pre-wrap mt-3">
                “If something has to be designed and{"\n"}invented, and you have
                to figure out how to{"\n"}ensure that the value of the thing you
                create{"\n"}is greater than the cost of the inputs, then{"\n"}
                that is probably my core skill.”
              </p>
              <span className="text-box d-block position-absolute"></span>
              <p className=" d-flex flex-start w-100 text-white fw-bold mt-3">
                Elon Musk
                <i
                  className="bi bi-check d-flex flex-center bg-success ms-1 rounded-circle fs-5 text-white"
                  style={{ width: "20px" }}
                ></i>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
