const socialNetworks = [
  {
    name: "telegram",
    href: "https://google.com",
  },
  {
    name: "instagram",
    href: "https://google.com",
  },
  {
    name: "facebook",
    href: "https://google.com",
  },
  {
    name: "twitter",
    href: "https://google.com",
  },
].map((e) => ({ icon: `bi bi-${e.name}`, ...e }));
export default socialNetworks;
