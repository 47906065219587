import { Col, Row } from "react-bootstrap";
import { Button } from "../../../components";

export default function Step1({ submit = () => {} }) {
  return (
    <Row>
      <Col xs="12" md="10" lg="6" className="d-flex flex-column flex-center">
        <i className="fs-1 text-warning bi bi-exclamation-octagon-fill" />
        <h6 className="text-danger">توجه!</h6>
        <p className="w-100 text-center">
          شما با ثبت نام و تکمیل اطلاعات احراز هویتی در سامانه پیشرو تایید
          میکنید:
        </p>
        <ol className="w-100 p-3 bg-light mt-3 rounded pe-4 text-justify">
          <li className="mb-3">
            تمامی مبالغ از حساب شخصیتان و با مسئولیت خودتان پرداخت میشود
          </li>
          <li className="mb-3">
            حساب و کارت های بانکی خود را برای خرید و جابجایی پول در اختیار
            دیگران قرار نداده اید.
          </li>
          <li className="mb-3">
            با آگاهی کامل و برای شخص خودتان اقدام به ثبت نام و خرید و فروش می
            کنید.
          </li>
          <li>تمامی عواقب سوء استفاده از حساب کاربری پیشرو را می پذیرید.</li>
        </ol>
        <Button variant="violet" className="text-light" onClick={submit}>
          پذیرش
        </Button>
      </Col>
    </Row>
  );
}
