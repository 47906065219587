import React from "react";
import TopSection from "./Components/TopSection";
import Services from "./Components/Services";
import MiddleSection from "./Components/MiddleSection";
// import CommentSection from "./Components/CommentSection";
import LastNews from "./Components/LastNews";
import Cryptos from "./Components/Cryptos";
import "./index.scss";
export default function Home() {
  return (
    <div className="Home d-flex flex-column">
      <TopSection />
      <Cryptos />
      <Services />
      <MiddleSection />
      {/* <CommentSection /> */}
      <LastNews />
    </div>
  );
}
