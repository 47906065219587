import { NavLink } from "react-router-dom";
import { navItems } from "../../constants";
export default function Navbar() {
  return (
    <nav className="Navbar d-lg-flex d-none flex-center text-secondary col-gap-3">
      {navItems.landing.map((e) => (
        <NavLink key={e.path} to={e.path}>
          {e.label}
        </NavLink>
      ))}
    </nav>
  );
}
