const steps = [
  {
    label: "پذیرش قوانین",
  },
  {
    label: "تایید ایمیل",
  },
  {
    label: "کد ملی",
  },
  {
    label: "آدرس کد پستی تلفن ثابت",
  },
  {
    label: "عکس با مدارک",
  },
];
export default steps;
