import React, { useRef } from "react";
import { Col } from "react-bootstrap";
import { Button, Input, Form } from "../../../components";
import codeMelli from "../../../assets/icons/nationalCode.png";
import { rules } from "../../../constants";
import { axios } from "../../../boot";

export default function Step3({ data, setData, submit }) {
  const inputRef = useRef();
  const submitNationalCard = () => {
    const url = "/user/CardMelli";
    const body = new FormData();
    body.append("codeMelli", data.codeMelli);
    body.append("image", data.melliCardImage);
    axios.post(url, body).then(({ data }) => {
      setData((p) => ({ ...p, melliCardImage: data.imageAddress }));
      submit();
    });
  };
  const imageName = data.melliCardImage?.name || data.melliCardImage || "";
  return (
    <Form onSubmit={submitNationalCard} className="row">
      <Col xs="12" className="d-flex flex-center">
        <div className="wallet-icon-section d-flex flex-center rounded-circle bg-primary bg-opacity-25">
          <img
            width="70"
            height="70"
            className="object-fit-contain"
            src={codeMelli}
            alt="codeMelliIcon"
          />
        </div>
      </Col>
      <h5 className="fw-bold col-12 text-center">کد ملی</h5>
      <p className="fs-7 col-12 text-center text-gray">
        کد ملی صرفا جهت واقعی بودن کاربر دریافت می شود. همچنین آپلود تصویر کارت
        ملی به منظور جلوگیری از ثبت کد ملی دیگران می باشد .
      </p>
      <Col xs="12" lg="6">
        <Input
          rules={rules.nationalCode}
          value={data.codeMelli}
          setValue={(codeMelli) => setData((p) => ({ ...p, codeMelli }))}
          label="کد ملی:"
        />
      </Col>
      <Col xs="12" lg="6">
        <Input
          readOnly
          rules={rules.required}
          label="تصویر کارت ملی:"
          value={imageName}
          append={
            <Button
              onClick={() => inputRef.current.click()}
              variant="light-gray"
            >
              انتخاب فایل
            </Button>
          }
        />
        <input
          onChange={({ target }) =>
            setData((p) => ({ ...p, melliCardImage: target.files[0] }))
          }
          ref={inputRef}
          type="file"
          className="d-none"
        />
      </Col>
      <Col xs="12" className="d-flex flex-center">
        <Button type="submit" variant="violet">
          مرحله بعد
        </Button>
      </Col>
    </Form>
  );
}
