import React, { useEffect, useRef, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import {
  BackBtn,
  Button,
  CheckBox,
  Form,
  Input,
  Modal,
  NeedAuth,
  Select,
} from "../../../components";
import { axios } from "../../../boot";
import StepIndex from "./StepIndex";
import { Link } from "react-router-dom";
import { rules } from "../../../constants";
import { copyText, toast, source } from "../../../methods";
import "./index.scss";
export default function SellCrypto() {
  const inputFileRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const crypto = location.state;
  const [data, setData] = useState({
    mem: crypto.mem,
    coinsymbol: crypto.symbol,
    weWallet: "",
    coinPrice: crypto.sellPriceToman,
  });
  const authentication = useSelector((s) => s.profile?.authentication ?? 0);
  const [enabledBtn, setEnabledBtn] = useState(false);
  const [cryptoWallets, setCryptoWallets] = useState([]);
  const [bankCards, setBankCards] = useState([]);
  const [showQrCode, setShowQrCode] = useState(false);
  const handleUploadClick = () => {
    if (hasImage) {
      const imageObj = { image: undefined, imageName: undefined };
      setData((p) => ({ ...p, ...imageObj }));
      inputFileRef.current.value = "";
      return;
    }
    inputFileRef.current.click();
  };
  const copyMem = () => {
    if (!data.mem) return;
    copyText(data.mem)
      .then(() => {
        const text = "تگ با موفقیت کپی شد.";
        toast({ text });
      })
      .catch((e) => {
        const text = "خطا در کپی کردن تگ.";
        toast({ text, type: "error" });
      });
  };
  const copyWallet = () => {
    if (!data.weWallet) return;
    copyText(data.weWallet)
      .then(() => {
        const text = "آدرس ولت با موفقیت کپی شد.";
        toast({ text });
      })
      .catch((e) => {
        const text = "خطا در کپی کردن آدرس ولت.";
        toast({ text, type: "error" });
      });
  };
  const getCryptoWallet = () => {
    const url = "/wallet/get_wallets";
    const body = { symbol: crypto.symbol };
    axios.post(url, body).then((res) => {
      const data = res.data.map((item) => ({
        name: item.name,
        id: item._id,
        wallet: item.address,
        qrCode: item.qrCodeimage,
        mem: !!item.mem && item.mem,
      }));
      setCryptoWallets(data);
    });
  };
  const getBankCards = () => {
    const url = "/creditcards/get_cards";
    axios.post(url).then(({ data }) => {
      setBankCards(
        data.map((e) => ({
          id: e._id,
          name: `${e.bankName} - (${e.cardNumber})`,
        }))
      );
    });
  };
  const handleSetValue = (key = "", value = "") => {
    const data = {};
    const sellPrice = crypto.sellPriceToman;
    if (key === "coinAmount") {
      data.totalPayable = +value * sellPrice;
    } else if (key === "totalPayable") {
      data.coinAmount = +value / sellPrice;
    } else if (key === "networkid") {
      const { mem } = cryptoWallets.find((e) => e.id === value);
      data.mem = mem;
    }
    setData((p) => ({ ...p, ...data, [key]: value }));
  };
  const submit = () => {
    const url = "/order/addsellOrder";
    const body = new FormData();
    Object.keys(data).forEach((key) => {
      body.append(key, data[key]);
    });
    axios.post(url, body).then(() => {
      const text = "سفارش شما ثبت شد";
      toast({ text });
      navigate("/dashboard");
    });
  };
  const hasImage = !!data.image;
  const formControls1 = [
    {
      label: "مقدار ارز",
      state: "coinAmount",
      rules: rules.required,
      type: "number",
    },
    {
      label: "میزان دریافتی",
      state: "totalPayable",
      type: "price",
      rules: rules.sellPrice,
      append: <i className="input-group-text">تومان</i>,
    },
    {
      tag: Select,
      label: "نوع شبکه",
      items: cryptoWallets,
      state: "networkid",
      rules: rules.required,
    },
  ];
  const formControls2 = [
    {
      label: "آدرس کیف پول پیشرو",
      state: "weWallet",
      readOnly: true,
      rules: [() => true],
      prepend: data.networkid && (
        <button
          type="button"
          onClick={copyWallet}
          className="bi bi-files input-group-text"
        />
      ),
      append: data.networkid && (
        <button
          type="button"
          onClick={() => setShowQrCode(true)}
          className="bi bi-qr-code-scan input-group-text"
        />
      ),
    },
    !!data.mem && {
      label: "تگ ریپل، ممو استلار، پیمنت آیدی",
      state: "mem",
      readOnly: true,
      rules: [() => true],
      prepend: data.networkid && (
        <button
          type="button"
          onClick={copyMem}
          className="bi bi-files input-group-text"
        />
      ),
    },
    {
      tag: Select,
      label: "واریز به شماره کارت",
      state: "cardid",
      rules: rules.required,
      items: bankCards,
    },
    {
      label: "توضیحات",
      state: "userDecription",
      rules: [() => true],
    },
  ].filter((e) => e);
  const formControls3 = [
    {
      label: "TXID",
      state: "txid",
      rules: rules.required,
    },
    {
      label: "تصویر رسید",
      state: "imageName",
      append: (
        <Button
          onClick={handleUploadClick}
          variant={hasImage ? "danger text-white" : "light-gray"}
        >
          {hasImage ? "حذف فایل" : "انتخاب فایل"}
        </Button>
      ),
      rules: rules.required,
      readOnly: true,
    },
  ];
  const formControls = [formControls1, formControls2, formControls3];

  useEffect(getBankCards, []);
  useEffect(getCryptoWallet, []);
  useEffect(() => {
    const { wallet = "", qrCode = "" } =
      cryptoWallets.find((e) => e.id === data.networkid) ?? {};
    setData((p) => ({
      ...p,
      weWallet: wallet,
      qrCode,
    }));
  }, [data.networkid]);
  return (
    <div className="SellCrypto wrapper">
      <div>
        <BackBtn />
      </div>
      <NeedAuth />
      <div className="cryptoSymbol d-flex flex-column flex-center">
        <img
          width="75"
          height="75"
          className="object-fit-contain"
          src={crypto.image}
          alt={crypto.name}
        />
        <h6 className="fw-bold">
          {crypto.name}{" "}
          <span className="text-uppercase">({crypto.symbol})</span>
        </h6>
        <p>{Number(crypto.sellPriceToman.toFixed(5)).toLocaleString()} تومان</p>
      </div>
      <Form onSubmit={submit} className="row justify-content-start">
        <Col xs="12">
          <StepIndex index={1} />
        </Col>
        {formControls[0].map((item, index) => (
          <Col key={index} xs="12" lg="6">
            {React.createElement(item.tag ?? Input, {
              ...item,
              value: data[item.state],
              setValue: (val) => handleSetValue(item.state, val),
            })}
          </Col>
        ))}
        <Col xs="12">
          <StepIndex index={2} />
        </Col>
        <Col xs="12">
          <Alert variant="info">
            <p className="text-info">
              <i className="bi bi-exclamation-circle-fill fs-5 ms-1"></i>
              چنانچه ارز ارسالی شما با بیش از 30 دقیقه اختلاف زمانی به
              پیشروپرداخت برسد، مبلغ پرداختی در زمان دریافت ارز محاسبه خواهد شد.
            </p>
            {!!data.mem && (
              <p className="text-info">
                <i className="bi bi-exclamation-circle-fill fs-5 ms-1"></i>
                برای فروش این ارز، در هنگام ارسال ارز، حتما تگ یا ممو را نیز
                وارد کنید. در صورت ارسال بدون در نظر گرفتن تگ یا ممو، ارز شما به
                سامانه پیشرو نرسیده و دارایی شما از بین می‌رود. سامانه پیشرو
                هیچگونه مسئولیتی در قبال اشتباه کاربر در ارسال ارز ندارد.
              </p>
            )}
          </Alert>
        </Col>
        {formControls[1].map((item, index) => (
          <Col key={index} xs="12" lg="6">
            {React.createElement(item.tag ?? Input, {
              ...item,
              value: data[item.state],
              setValue: (val) => setData((p) => ({ ...p, [item.state]: val })),
            })}
          </Col>
        ))}
        <Col xs="12">
          <StepIndex index={3} />
        </Col>
        <Col xs="12" className="d-flex col-gap-1">
          <Alert variant="info" className="text-start">
            <p className="text-info">
              <i className="bi bi-exclamation-circle-fill fs-5 ms-1"></i>
              مبالغ پرداختی در روزهای کاری و تا 48 ساعت بعد از ثبت درخواست از
              طریق سیستم پایا به حساب شما واریز خواهد شد. مقدار ارزی که قصد فروش
              آن را دارید به طور دقیق و صحیح وارد نمایید و دقیقا همان مقدار را
              در یک تراکنش ارسال نمایید.
            </p>
            <p className="text-info">
              <i className="bi bi-exclamation-circle-fill fs-5 ms-1"></i>
              در صورتی که مقدار دریافتی ما طی 30 دقیقه آینده دقیق معادل مبلغ
              وارد شده شما نباشد، فروش شما به صورت دستی باید بررسی و تایید شود.
            </p>
          </Alert>
        </Col>
        {formControls[2].map((item, index) => (
          <Col key={index} xs="12" lg="6">
            {React.createElement(item.tag ?? Input, {
              ...item,
              value: data[item.state],
              setValue: (val) => setData((p) => ({ ...p, [item.state]: val })),
            })}
          </Col>
        ))}
        <input
          ref={inputFileRef}
          className="d-none"
          onChange={({ target }) => {
            const image = target.files[0];
            setData((p) => ({ ...p, image, imageName: image?.name }));
          }}
          type="file"
          accept=".jpg, .png, .jpeg"
        />
        <Col xs="12">
          <Row className="payment-section bg-primary bg-opacity-10 border border-primary rounded py-3 px-2 mb-3">
            <Col xs="12" lg="6" className="d-flex flex-column flex-center px-0">
              <CheckBox
                setValue={setEnabledBtn}
                value={enabledBtn}
                className="my-auto w-fit"
                variant="violet"
                label={
                  <>
                    <Link
                      to="/"
                      className="text-violet text-decoration-underline fw-bold"
                    >
                      شرایط فروش
                    </Link>{" "}
                    سایت پیشرو پرداخت را مطالعه و پذیرفتم.
                  </>
                }
              />
              <Button
                disabled={!enabledBtn || authentication !== 1}
                type="submit"
                variant="violet"
                className="w-75 mt-auto"
              >
                ثبت
              </Button>
            </Col>
            <Col xs="12" lg="6" className="d-flex flex-column flex-center px-0">
              <p className="h6 text-dark-blue fw-bold text-center my-auto">
                مبلغ دریافتی:
                <br />
                <span className="fs-5">
                  {data?.totalPayable?.toLocaleString() ?? 0} تومان
                </span>
              </p>
            </Col>
          </Row>
        </Col>
      </Form>
      <Modal
        show={showQrCode}
        onHide={setShowQrCode}
        title="qr code آدرس کیف پول پیشرو"
      >
        <img src={source(data.qrCode)} alt="qrCode" className="w-100" />
      </Modal>
    </div>
  );
}
