import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Pagination, Table } from "../../../components";
import { axios } from "../../../boot";

export default function AllCryptos({ isBuy, transactionLabel }) {
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search") ?? "";
  const [allCryptos, setAllCryptos] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const getAllCryptos = () => {
    const url = "/coin/get_price";
    const body = { page: activePage, search };
    axios.post(url, body).then(({ data }) => {
      getFavCryptos(data.coins);
      setTotalPages(data.totalPages);
    });
  };
  const getFavCryptos = (coins = []) => {
    const url = "/user/getUserFav";
    axios.post(url).then(({ data }) => {
      const favListId = data.favList.map((e) => e._id);
      const cryptos = coins.map((e) => ({
        ...e,
        isFav: favListId.includes(e._id),
      }));
      setAllCryptos(cryptos);
    });
  };
  const handleIsFav = (type = "", coinsymbol = "") => {
    const url = `/user/${type}FavCoin`;
    const body = { coinsymbol };
    axios.post(url, body).then(() => {
      setAllCryptos((p) =>
        p.map((e) => {
          if (e.symbol === coinsymbol) {
            e.isFav = !e.isFav;
          }
          return e;
        })
      );
    });
  };
  const showChangeValue = (val = 0) => {
    if (val < 0) return <span className="text-danger">{val}%</span>;
    if (val > 0) return <span className="text-success">+{val}%</span>;
    return <span className="text-dark">{0}</span>;
  };
  useEffect(getAllCryptos, [activePage, search]);
  return (
    <React.Fragment>
      <Table className="text-center bg-light-blue rounded">
        <thead className="text-secondary">
          <tr>
            <td className="text-start">نام ارز</td>
            <td>قیمت(دلار)</td>
            <td>قیمت(تومان)</td>
            <td>تغییرات (یک هفته)</td>
            <td>حجم بازار</td>
            <td></td>
          </tr>
        </thead>
        <tbody className="text-dark-blue fw-bold bg-white border-white">
          {allCryptos.map((item) => (
            <tr key={item._id}>
              <td className="text-start">
                <div className="w-fit d-flex flex-center col-gap-2">
                  <button
                    className={`bi bi-star${item.isFav ? "-fill" : ""} text-${
                      item.isFav ? "warning" : "secondary"
                    }`}
                    onClick={() =>
                      handleIsFav(item.isFav ? "delete" : "add", item.symbol)
                    }
                  />
                  <img
                    width="35"
                    height="35"
                    className="object-fit-contain"
                    src={item.image}
                    alt={item.name}
                  />
                  <p>
                    <span className="text-dark-blue">{item.persianName}</span>
                    <br />
                    <span className="text-secondary fs-7">
                      {item.name}~
                      <span className="text-uppercase">{item.symbol}</span>
                    </span>
                  </p>
                </div>
              </td>
              <td dir="ltr" className="white-space-pre-wrap">
                $ {item.priceUsdt}
                {"\n"}
                {showChangeValue(item.change24h?.toFixed(2))}
              </td>
              <td>
                {Number(
                  item[isBuy ? "buyPriceToman" : "sellPriceToman"]?.toFixed(5)
                ).toLocaleString()}
              </td>
              <td dir="ltr">{showChangeValue(item.change7d?.toFixed(2))}</td>
              <td>${Number(item.marketCap).toLocaleString()}</td>
              <td>
                <Link
                  to={item._id}
                  state={item}
                  className="btn btn-primary py-1 text-white px-4"
                >
                  {transactionLabel}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        activePage={activePage}
        setActivePage={setActivePage}
        totalPages={totalPages}
      />
    </React.Fragment>
  );
}
