import React from "react";
import { Col } from "react-bootstrap";
import { Badge, Table } from "../../../../../components";
import { ticketStatus } from "../../../../../constants";
import { moment } from "../../../../../boot";
import { Link, useNavigate } from "react-router-dom";

export default function TicketSection({ tickets = [] }) {
  const navigate = useNavigate();
  const showStatus = (status = 0) => {
    const { color, name } = ticketStatus.find((e) => e.id === status);
    return <Badge label={name} variant={color} />;
  };
  return (
    <Col xs="12" md="6">
      <div className="wrapper">
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="text-violet m-0">تیکت‌ها</h5>
          <Link to="/tickets" className="d-flex flex-center text-blue">
            مشاهده همه
            <i className="bi bi-arrow-left" />
          </Link>
        </div>
        <Table>
          <thead className="text-secondary">
            <tr>
              <td>شماره تیکت</td>
              <td>موضوع</td>
              <td>وضعیت</td>
              <td>تاریخ ارسال</td>
            </tr>
          </thead>
          <tbody>
            {tickets.map((e, i) => (
              <tr
                key={e._id}
                onClick={() => navigate(`/tickets/${e._id}`, { state: e })}
              >
                <td>{i + 1}</td>
                <td>{e.title}</td>
                <td>{showStatus(e.status)} </td>
                <td>{moment.miladiToShamsi({ date: e.updatedAt })}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Col>
  );
}
