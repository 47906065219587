import { useEffect, useState } from "react";
import { Table } from "../../../components";
import { axios, moment } from "../../../boot";
import { Alert } from "react-bootstrap";
import { payStatus } from "../../../constants";
import { useNavigate } from "react-router";

export default function BuyOrders({ isBuy }) {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const getBuyOrders = () => {
    const url = `/order/${isBuy ? "getuserbuyorders" : "getusersellorders"}`;
    axios.post(url).then(({ data }) => {
      setOrders(data.orders);
    });
  };
  const showStatus = (status = 0) => {
    const { color, name } = payStatus.find((e) => e.id === status);
    return (
      <Alert variant={color} className="w-fit border-0 p-2 mx-auto">
        {name}
      </Alert>
    );
  };
  useEffect(getBuyOrders, []);
  return (
    <Table className="text-center bg-light-blue rounded">
      <thead className="text-secondary">
        <tr>
          <td>نام ارز</td>
          <td>مقدار</td>
          <td>قیمت(تومان)</td>
          <td>{isBuy ? "آدرس کیف پول" : "TXID"}</td>
          {/* <td>Txid</td> */}
          <td>تاریخ</td>
        </tr>
      </thead>
      <tbody className="text-dark-blue fw-bold bg-white border-white">
        {orders.map((e) => (
          <tr
            key={e._id}
            onClick={() =>
              navigate(`/receipt/${isBuy ? "buy" : "sell"}`, { state: e })
            }
          >
            <td>
              {e.coin?.persianName}
              <br />
              <span className="text-secondary fs-7">
                {e.coin?.name} ~{" "}
                <span className="text-uppercase">{e.coin?.symbol}</span>
              </span>
            </td>
            <td>{e.coinAmount}</td>
            <td className="white-space-pre-wrap">
              {Number(e[isBuy ? "pay" : "totalPayable"]).toLocaleString()}
            </td>
            <td>{e[isBuy ? "userWallet" : "txid"]}</td>
            {/* <td>ghjklpo</td> */}
            <td>{moment.miladiToShamsi({ date: e.createdAt })}</td>
            <td>{showStatus(e[isBuy ? "orderStatus" : "receiveStatus"])}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
