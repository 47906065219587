const payStatus = [
  {
    id: 0,
    name: "در انتظار تایید",
    color: "warning",
  },
  {
    id: 1,
    name: "تایید شده",
    color: "success",
  },
  {
    id: 2,
    name: "رد شده",
    color: "danger",
  },
];
export default payStatus;
