import React, { useEffect, useRef, useState } from "react";
import { Col, Row , Badge } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { Outlet, useLocation } from "react-router";
import { navItems } from "../../constants";
import { axios } from "../../boot";
import pishroPardakhtIcon from "../../assets/logos/logo-text-light.svg";
import vector from "../../assets/icons/vector.png";
import logOutIcon from "../../assets/icons/Export.png";
// import wallet from "../../assets/icons/account_balance_wallet_black_24dp 1.png";
import notification from "../../assets/icons/notifications_black_24dp (1) 1.png";
import people from "../../assets/icons/people_outline_black_24dp 1.png";
import LogoutModal from "./LogoutModal";
import Accordion from "./Accordion";
import { useDispatch, useSelector } from "react-redux";
import { source } from "../../methods";
import SearchBox from "./SearchBox";
import "./index.scss";
export default function Users() {
  const location = useLocation();
  const [ message , setMessages ] = useState([])
  const headerButtons = [
    {
      icon: notification,
      title: "notification",
      href: "/messages",
    },
    {
      icon: people,
      title: "people",
      href: "/my-information",
    },
    // {
    //   icon: wallet,
    //   title: "wallet",
    //   href: "/wallet",
    // },
  ];
  const dirLabels = {
    "": ["خانه"],
    dashboard: ["داشبورد"],
    "buy-cryptos": ["خدمات رمز ارز", "خرید ارز"],
    "sell-cryptos": ["خدمات رمز ارز", "فروش ارز"],
    "increase-credit-online": ["پرداخت‌های من", "افزایش اعتبار آنلاین"],
    "increase-credit-offline": ["پرداخت‌های من", "افزایش اعتبار آفلاین"],
    "bank-cards": ["کارت‌های بانکی"],
    tickets: ["تیکت‌ها"],
    messages: ["پیام‌ها"],
    "my-information": ["اطلاعات من"],
    authentication: ["تکمیل اطلاعات"],
    "new-ticket": ["ساخت تیکت جدید"],
    receipt: ["جزئیات تراکنش"],
  };
  const activeDir = location.pathname
    .split("/")
    .map((key) => dirLabels[key])
    .filter(Boolean)
    .flat();
  const renderNavItems = () => {
    const elements = [];
    navItems.users.forEach((item, index) => {
      if ("path" in item) {
        const element = (
          <li key={index} className="w-100">
            <NavLink
              to={item.path}
              className={({ isActive }) =>
                `w-100 d-flex align-items-center p-2 ${
                  isActive ? "bg-white text-violet active" : ""
                }`
              }
            >
              {item.title}
              <i className="bi bi-arrow-left me-auto transition" />
            </NavLink>
          </li>
        );
        elements.push(element);
      }
      if ("children" in item) {
        const element = (
          <li key={index} className="w-100">
            <Accordion title={item.title}>
              <div className="d-flex flex-column">
                {item.children.map((item, index) => (
                  <NavLink
                    key={index}
                    to={item.path}
                    className={({ isActive }) =>
                      ` d-flex align-items-center justify-content-between transition p-2 ${
                        isActive ? "text-violet active" : ""
                      }`
                    }
                  >
                    {item.title}
                    <i className="bi bi-arrow-left transition text-violet" />
                  </NavLink>
                ))}
              </div>
            </Accordion>
          </li>
        );
        elements.push(element);
      }
    });
    return elements;
  };
  const sidebar = useRef();
  const dispatch = useDispatch();
  const [showSidebar, setShowSidebar] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const profile = useSelector((s) => s.profile);
  const setProfile = (data) => {
    dispatch({ type: "SET_PROFILE", data });
  };
  const handleShowSidebar = () => {
    sidebar.current.classList.toggle("active", showSidebar);
  };
  const handleChangeLocation = () => {
    setShowSidebar(false);
  };
  const getProfile = () => {
    const url = "/user/getMyProfile";
    axios.post(url).then(({ data }) => {
      setProfile(data.user[0]);
    });
  };

  const getMessages = () => {
    const url = "/message/getMessage";
    axios.post(url).then(({ data }) => {
      setMessages(data);
      console.log(data)
    });
  };

  const hasProfile = Boolean(profile.profilePic);
  useEffect(getMessages, []);
  useEffect(handleChangeLocation, [location.pathname]);
  useEffect(handleShowSidebar, [showSidebar]);
  useEffect(getProfile, []);
  return (
    <Row className="Users px-1 py-3 align-items-start">
      <Col ref={sidebar} xs="12" lg="3" xl="2" className="sidebar p-0">
        <div className="sidebar-menu d-flex flex-column align-items-center rounded text-white row-gap-3 py-3">
          <div className="px-4 w-100">
            <Link to="/dashboard">
              <img className="w-100" src={pishroPardakhtIcon} alt="logo" />
            </Link>
          </div>
          <div className="profile rounded bg-light bg-opacity-10">
            <div className="content d-flex flex-column flex-center row-gap-3 py-2 w-100 rounded">
              <img
                width="60"
                height="60"
                className="bg-light bg-opacity-10 rounded-circle object-fit-cover"
                src={hasProfile ? source(profile.profilePic) : vector}
                alt="vector"
              />
              <h6 className="text-center">{profile.name}</h6>
            </div>
            <Row className="text-center text-white py-2 fs-7">
              <Col
                xs="6"
                className="d-flex flex-column flex-center row-gap-1 border-start border-light"
              >
                <h6 className="mb-0 fs-7">امتیاز من</h6>
                <span>{profile.score?.toLocaleString()}</span>
              </Col>
              <Col
                xs="6"
                className="d-flex flex-column flex-center row-gap-1 border-end border-light"
              >
                <h6 className="mb-0 fs-7">اعتبار خرید من</h6>
                <span>
                  {profile.credit?.toLocaleString()} <sub>تومان</sub>
                </span>
              </Col>
            </Row>
          </div>
          <ul className="nav-items w-100 pe-3 d-flex flex-column flex-center row-gap-2">
            {renderNavItems()}
          </ul>
          <button
            onClick={() => setShowLogoutModal(true)}
            className="text-white mt-auto d-flex flex-center col-gap-1 bg-transparent border-0"
          >
            <img width="25" src={logOutIcon} alt="logOutIcon" />
            خروج از حساب کاربری
          </button>
        </div>
        <button
          onClick={() => setShowSidebar(false)}
          className="hide-btn d-block d-lg-none"
        />
      </Col>
      <Col xs="12" lg="9" className="px-2">
        <Row as="header" className="justify-content-between">
          <Col xs="12" md="6">
            <div className="d-flex align-items-end ms-auto">
              <i
                className="bi bi-list fs-1 d-flex d-lg-none"
                onClick={() => setShowSidebar(true)}
              />
              <div className="breadcrumb d-flex flex-column gap-1 me-3">
                <h5 className="fs-6 text-info">{activeDir.at(-1)}</h5>
                <h6 className="d-flex align-items-center fs-7 text-secondary">
                  {activeDir.map((e, i) => (
                    <React.Fragment key={i}>
                      {i !== 0 && <i className="bi bi-chevron-left" />}
                      <span
                        className={`mx-1 ${
                          i === activeDir.length - 1 && "active"
                        }`}
                      >
                        {e}
                      </span>
                    </React.Fragment>
                  ))}
                </h6>
              </div>
            </div>
          </Col>
          <Col xs="12" md="6">
            <div className="w-100 d-flex align-items-center justify-content-start justify-content-lg-end col-gap-1">
              {headerButtons.map((item, index) => (
                <Link
                  key={index}
                  to={item.href}
                  className="bg-transparent border-0"
                  style={{position : "relative"}}
                >
                  {item.title == "notification" && <Badge className="d-flex justify-content-center align-items-center rounded-circle" style={{ width : "18px" , height : '18px' , position : "absolute" , top : "-10px", right : "0px"  ,  zIndex : "2"}} pill bg="primary" text="white"><span className="mt-1">{message.length}</span></Badge>}
                  <img
                    style={{position : "relative" , zIndex : "1"}}
                    width="40"
                    height="40"
                    className="object-fit-contain bg-secondary rounded-circle p-2"
                    src={item.icon}
                    alt={item.title}
                    
                  />
                </Link>
              ))}
              <SearchBox />
            </div>
          </Col>
        </Row>
        <main className="py-2">
          <Outlet />
        </main>
      </Col>
      <LogoutModal show={showLogoutModal} onHide={setShowLogoutModal} />
    </Row>
  );
}
