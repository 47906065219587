import React, { useEffect } from "react";
import { Col } from "react-bootstrap";
import { Form, Input, Button, Select } from "../../../components";
import { rules } from "../../../constants";
import { useGetStates, useGetCities } from "../../../hooks";
export default function Step4({ data, setData, submit }) {
  const [states] = useGetStates();
  const [cities, getCities] = useGetCities();
  const formControls = [
    {
      tag: Select,
      label: "استان",
      state: "state",
      filter: true,
      items: states,
    },
    {
      tag: Select,
      label: "شهرستان",
      state: "city",
      filter: true,
      items: cities,
    },
    {
      as: "textarea",
      label: "آدرس",
      state: "address",
    },
    {
      label: "کدپستی",
      state: "postalCode",
      type: "number",
    },
    {
      label: "پلاک",
      state: "plaque",
      type: "number",
    },
    {
      label: "واحد",
      state: "unit",
      type: "number",
    },
    {
      label: "تلفن ثابت",
      state: "phoneSabet",
      type: "number",
    },
  ].map((e) => ({ rules: rules.required, ...e }));
  useEffect(() => getCities(data.state), [data.state]);
  return (
    <Form onSubmit={submit} className="row">
      {formControls.map((e, i) => (
        <Col key={i} xs="12" lg={e.state === "address" ? "12" : "6"}>
          {React.createElement(e.tag ?? Input, {
            ...e,
            value: data[e.state],
            setValue: (val) => setData((p) => ({ ...p, [e.state]: val })),
          })}
        </Col>
      ))}
      <Col xs="12" className="d-flex flex-center">
        <Button type="submit" variant="violet">
          مرحله بعد
        </Button>
      </Col>
    </Form>
  );
}
